import { Injectable } from '@angular/core';
import { UtilService } from './util.service';
import { MessageService } from 'primeng/api';
import {
  ApplicationInsightsService,
  SeverityLevel
} from './appinsight.service';
import { getErrorMessage, MessageType } from '../constants';
import { ApiInsightsError } from '../models/appInsights.error.model';
import { APIRequest } from '../models/api.request.model';
import { APIMethod, ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { EventTelemetry } from '../models/application-insights/event.telemetry.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {
  private toastKey = 'RequestFailed';

  constructor(
    private utilService: UtilService,
    private apiService: ApiService,
    private messageService: MessageService,
    private appInsightService: ApplicationInsightsService
  ) {}

  handleErrorResponse(errorResponse: any, component?: string, method?: string) {
    const errorInfo = this.utilService.prepareAppInsightError(errorResponse, component, `${component} - ${method}`,
      { url: errorResponse.url, status: errorResponse.status });

    this.AppInsightLogError(errorInfo).subscribe(
      () => {}, err => { this.appInsightService.trackException(errorResponse, SeverityLevel.Error); });

    let page = ((errorResponse.url).split('/'));
    this.appInsightService.trackEvent(new EventTelemetry('APIRequestFailed'),
    { API: ((page.includes('token')) ? 'ChargeToken' : page.splice(4, 1).toString() ) });

    this.appInsightService.trackException(errorResponse, SeverityLevel.Error);

    const errorData = errorResponse.error;
    if (errorResponse.status !== 401) {
      if (errorData && errorData.hasOwnProperty('Messages')) {
        this.createToastMessages(errorData)
      } else {
        this.messageService.add(getErrorMessage(MessageType.API_Request_Failed, this.toastKey));
      }
    }
  }

  getAppInsightErrorObject(errorResponse, component, method) {
    return this.utilService.prepareAppInsightError(
      errorResponse,
      component,
      `${component} - ${method}`,
      { url: errorResponse.url, status: errorResponse.status }
    );
  }

  public AppInsightLogError(appInsightError: ApiInsightsError) {
    const apiRequest: APIRequest = new APIRequest(
      'Error',
      APIMethod.POST,
      {
        Error: JSON.stringify(appInsightError.Error),
        ErrorDescription: appInsightError.ErrorDescription,
        ErrorMethod: appInsightError.ErrorMethod,
        Path: appInsightError.Path,
        Comment: appInsightError.Comment,
        Category: appInsightError.Category
      }
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  createToastMessages(errorData) {
    errorData.Messages.forEach(err => {
      this.messageService.add(
        getErrorMessage(
          MessageType.Received_Error_Message,
          this.toastKey,
          err.Message
        )
      );
    });
  }
}
