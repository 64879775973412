
export class PageViewTelemetry {
  name?: string;
  uri?: string;
  refUri?: string;
  pageType?: string;
  isLoggedIn?: boolean;
  properties?: {
      duration?: number;
      [key: string]: any;
  };

  constructor(name?: string, uri?: string, refUri?: string, pageType?: string, isLoggedIn?: boolean, properties?: { [name: string]: string }) {
    this.name = name;
    this.uri = uri;
    this.refUri = refUri;
    this.pageType = pageType;
    this.isLoggedIn = isLoggedIn;
    this.properties = properties;
  }
}
