import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { FilteredComponentRoutes } from '../constants';
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const urlRoute = req.url.split('/').splice(2, 1).toString();

    if (!FilteredComponentRoutes.includes(urlRoute)) {
      this.loaderService.show();
      return next.handle(req).pipe(
        finalize(() => {
          this.loaderService.removeRequest();
          if (this.loaderService.getRequestCount() === 0) {
            this.loaderService.hide();
          }
        })
      );
    } else {
      return next.handle(req).pipe( finalize(() => {
        // console.log(req);
      }));
    }
  }
}
