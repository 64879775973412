import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import {
  PaymentType,
  deleteProps,
  emailRegexPattern,
  emailMaxLength
} from '../../../shared/constants';
import { Subscription } from 'rxjs';
import { TransactionService } from '../../services/transaction.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Campaign } from '../../models/Campaign';
import { Member } from '../../models/Member';
import {
  ApplicationInsightsService
} from 'src/app/shared/services/appinsight.service';
import { UserPaymentData } from '../../models/PaymentInfo';
import { PaymentService } from '../../services/payment.service';
import { DonatePayment } from '../../models/DonatePayment';
import { DonorInfo } from '../../models/donorInfo';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { TraceTelemetry } from 'src/app/shared/models/application-insights/trace.telemetry.model';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'feature-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit, OnDestroy {
  donateForm: FormGroup;
  donorInfo: DonorInfo;
  isVariableAmount: boolean;
  selectedCampaign: Campaign;
  otherAmount: number;
  campaigns: Campaign[];

  inputEmail: string;
  donateAssociationType: string;

  isNotes = false;
  submitted = false;
  isAmountIcon = false;
  isGuestDonate = false;
  isMemberDonate = false;
  isManualPayment = false;
  inputBarClosed: boolean;
  isGetCampaignLoading = true;
  isEMVTransactionEnabled = false;

  getCampaignSubscription: Subscription;
  changeChapterSubscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private emitterService: EmitterService,
    private transactionService: TransactionService,
    private storageService: StorageService,
    private applicationInsightsService: ApplicationInsightsService,
    private paymentService: PaymentService,
    private errorService: ErrorHandleService,
  ) {
    this.donateForm = new FormGroup({
      donorName: new FormControl(''),
      radioSelect: new FormControl(Number),
      donationAmount: new FormControl('', [
        Validators.required,
        Validators.max(999999.99),
        Validators.min(0.01),
        Validators.pattern(/^\d{0,}(\.\d{1,2})?$/)
      ]),
      shouldSendEmailReceipt: new FormControl(false),
      receiptEmail: new FormControl('', [
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]),
      notes: new FormControl(''),
      billhighwayId: new FormControl('')
    });
    this.isEMVTransactionEnabled = this.storageService.getConfigValue('isEMVTransactionEnabled');
  }

  checkAmount(amount) {
    if (!amount) {
      this.form.donationAmount.setValue(this.otherAmount);
      this.isVariableAmount = true;
    } else {
      this.isVariableAmount = false;
      this.form.donationAmount.setValue(amount.Amount);
    }
  }

  get form() {
    return this.donateForm.controls;
  }

  onActivate(componentReference) {
    switch (componentReference.type) {
      case 'member':
        this.isMemberDonate = true;
        componentReference.selectMember.subscribe((selectedMember: Member) => {
          this.navigationService.navigateToDonation();
          this.donorInfo = new DonorInfo();
          this.donorInfo.Name = selectedMember.MemberName;
          this.donorInfo.Email = selectedMember.EmailAddress;

          this.form.billhighwayId.setValue(selectedMember.BillhighwayAccountNumber);
          this.form.donorName.setValue(selectedMember.MemberName);
          this.form.receiptEmail.setValue(selectedMember.EmailAddress);
          this.inputEmail = this.form.receiptEmail.value;
          this.form.shouldSendEmailReceipt.setValue(true);
          this.donateAssociationType = componentReference.type;
          this.isMemberDonate = false;
          this.inputBarClosed = true;
        });
        break;

      case 'guest':
        this.isGuestDonate = true;
        componentReference.guestType = 'Donation';
        componentReference.guestSelect.subscribe((selectedGuest: DonorInfo) => {
          this.navigationService.navigateToDonation();
          this.donorInfo = deleteProps(selectedGuest);
          this.form.donorName.setValue(selectedGuest.Name);
          this.form.receiptEmail.setValue(selectedGuest.Email);
          this.inputEmail = this.form.receiptEmail.value;
          this.form.shouldSendEmailReceipt.setValue(true);
          this.donateAssociationType = componentReference.type;
          this.isGuestDonate = false;
          this.inputBarClosed = true;
        });
        break;

      case 'card-entry':
        this.isManualPayment = true;
        componentReference.userPaymentData = this.donateForm.valid ? this.getUserPaymentData() : false;
        this.emitterService.cardEntryPageOpened(true);
        break;
    }
  }

  onDeactivate() {
    this.isGuestDonate = false;
    this.isMemberDonate = false;
    this.isManualPayment = false;
    this.emitterService.cardEntryPageOpened(false);
  }

  resetDonateForm() {
    delete this.otherAmount;
    this.form.notes.reset();
    this.form.donationAmount.reset();
    this.form.radioSelect.setValue(Number);
    this.submitted = false;
    if (this.form.receiptEmail.invalid) {
      delete this.inputEmail;
      this.form.shouldSendEmailReceipt.setValue(false);
      this.form.receiptEmail.setValue('');
    }
  }

  getCampaigns() {
    this.isGetCampaignLoading = true;
    this.getCampaignSubscription = this.transactionService.getCampaignList(this.getContextData('ChapId')).subscribe(
      ({ Campaigns }) => {
        delete this.selectedCampaign;
        this.campaigns = Campaigns;
        this.isGetCampaignLoading = false;
      },
      error => {
        this.errorService.handleErrorResponse(error, 'Donation', 'getCampaign');
        this.isGetCampaignLoading = false;
      }
    );
  }

  getUserPaymentData() {
    let userPaymentData = new UserPaymentData();
    userPaymentData.UserId = this.getContextData('UserId');
    userPaymentData.Memo = this.form.notes.value;
    userPaymentData.Amount = Number((this.form.donationAmount.value * 100).toFixed());

    if (this.form.shouldSendEmailReceipt.value) {
      userPaymentData.ReceiptEmail = this.form.receiptEmail.value;
    }

    // If Associate Donation to Member
    if (this.form.billhighwayId.value) {
      userPaymentData.UserId = this.form.billhighwayId.value;
    } else {
      userPaymentData.UserId = 0;
    }

    if (this.selectedCampaign) {
      const donatePayment = new DonatePayment();
      donatePayment.CampaignId = this.selectedCampaign.CampaignId;
      donatePayment.CreateAsDeposit = 'false';

      if (this.donorInfo) {
        userPaymentData = { ...userPaymentData, ...{ DonorInfo: this.donorInfo } };
      }

      userPaymentData = { ...userPaymentData, ...donatePayment };
    }

    this.applicationInsightsService.trackTrace(
      new TraceTelemetry('Donation - GetUserPaymentData - Payment Data'), { UserPaymentData: JSON.stringify(userPaymentData) });

    return userPaymentData;
  }

  getContextData(key) {
    return this.storageService.getContext(key);
  }

  handleReceiptEmail() {
    if (!this.donateForm.get('shouldSendEmailReceipt').value) {
      this.form.receiptEmail.setValue('');
    } else {
      this.inputBarClosed = false;
      this.form.receiptEmail.setValue(this.inputEmail);
    }
  }

  donateAmount() {
    this.submitted = true;
    if (this.donateForm.get('shouldSendEmailReceipt').value) {
      this.donateForm.get('receiptEmail').setValidators([
        Validators.pattern(emailRegexPattern),
        Validators.required,
        Validators.maxLength(emailMaxLength)
      ]);
      this.donateForm.get('receiptEmail').updateValueAndValidity();
    } else {
      this.donateForm.get('receiptEmail').setValidators([
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]);
      this.donateForm.get('receiptEmail').updateValueAndValidity();
    }

    if (this.donateForm.valid && this.paymentService.validateBrowser()) {
      if (this.isEMVTransactionEnabled) {
        this.applicationInsightsService.trackTrace(
          new TraceTelemetry('Donation - DonationAmount - IsEmvEnabled'), { IsEmvEnabled: JSON.stringify(this.isEMVTransactionEnabled) });


        this.paymentService.startEMVTransaction(PaymentType.Donation, false, this.getUserPaymentData());


      } else {
        this.paymentService.startTransaction(PaymentType.Donation, false, this.getUserPaymentData());
      }
    }
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('DonationPage'));
    this.form.radioSelect.setValue(this.form.donationAmount.value);
    this.changeChapterSubscription = this.emitterService.chapterChange.subscribe(() => {
      this.donateAssociationType = '';
      this.donateForm.reset();
      this.getCampaigns();
    }
    );
  }

  ngOnDestroy(): void {
    this.getCampaignSubscription.unsubscribe();
    this.changeChapterSubscription.unsubscribe();
  }
}
