import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  isSupportedBrowser,
  getErrorMessage,
  MessageType
} from './shared/constants';
import { MessageService } from 'primeng/api';
import { StorageService } from './shared/services/storage.service';
import { APIMethod, ApiService } from '../app/shared//services/api.service';
import { APIRequest } from '../app/shared/models/api.request.model';
import { map } from 'rxjs/operators';
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private messageService: MessageService,
    private storageService: StorageService,
    public apiService: ApiService
  ) {
    this.getConfigurationData().subscribe(configurations => {
      this.storageService.applicationConfigurations = Object.keys(configurations).map((key) => configurations[key]);
      this.navEndEvents(this.storageService.getConfigValue('gtagConfigKey'));
    }, error => {
      this.navEndEvents(this.storageService.getConfigValue('gtagConfigKey'));
    });
  }

  navEndEvents(gtagConfigKey: string) {
    const navEndEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', gtagConfigKey, {
        pagePath: event.urlAfterRedirects
      });
    });
  }

  ngOnInit(): void {
    if (!isSupportedBrowser() && !this.storageService.isLoggedIn()) {
      setTimeout(() => {
        this.messageService.add(
          getErrorMessage(MessageType.Non_MagneFlex_Browser, 'AppToast')
        );
      });
    }
  }

  getConfigurationData() {
    const apiRequest: APIRequest = new APIRequest('Configuration', APIMethod.GET, '', environment.AnywhereAPI);

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }
}
