import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentType } from '../constants';
import { EmitterService } from './emitter.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private emitterService: EmitterService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.checkUserAccessPermission(this.getCurrentAccountFeature(next.routeConfig.path));
  }

  getCurrentAccountFeature(currentRoute) {
      switch (currentRoute) {
        case 'deposit':
          this.emitterService.accountFeatureChanged(PaymentType.Deposit);
          return PaymentType.Deposit;

        case 'donate':
          this.emitterService.accountFeatureChanged(PaymentType.Donation);
          return PaymentType.Donation;

        case 'member-payment':
          this.emitterService.accountFeatureChanged(PaymentType.PersonPayment);
          return PaymentType.PersonPayment;
      }
  }
}
