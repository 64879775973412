export class PaymentConfirmation {
  // TransactionDateTime: Date;
  LastFour: string;
  ConfirmationNumber: string;
  CardType: string;
  NameOnCard: string;
  Amount: number;
  Notes: string;
  Success: boolean;
  IsDuplicate: boolean;
  PaymentConfirmationNumber: number;
  Message: string;
}
