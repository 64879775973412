export const calendarConfig = {
  firstDayOfWeek: 0,
  dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  today: 'Today',
  clear: 'Clear'
};

export const PaymentType = {
  Deposit: 'Deposit',
  Donation: 'Donation',
  PersonPayment: 'Member Payment',
  ChargeToken: 'ChargeToken',
  CardPayment: 'CardPayment'
};

export const AccountFeature = {
  Deposit: 'CanAcceptAnywhereDeposits-Card',
  Donation: 'CanAcceptAnywhereDonations-Card',
  PersonPayment: 'CanAcceptAnywherePayments-Card'
};

export const FilteredComponentRoutes =  [
  'Campaigns',
  'CashAccounts',
  'ChartOfAccounts',
  'MemberBalance',
  'MemberInvoices',
  'Members'
];

export const NonAccessibleRoles =  [
  'chaptermember',
  'alumnimember'
];

export const emailRegexPattern = /^(\w([\.\-+]?))*([\w\-]\@)(\w+((-\w+)|(\w*))\.[a-zA-Z\d]{1,}){1,}$/
export const emailMaxLength = 255;

export enum MessageType {
  // Common
  API_Request_Failed,
  No_Account_Feature_Available,
  // Login
  OTP_Sent,
  Logout_Error,
  Login_Failed,
  Unauthorized_User,
  UserRole_Not_Allowed,
  Sent_UserName_Success,
  Search_Username_Error,
  Reset_Password_Success,
  // Transaction
  No_Chapter_Found,
  No_Account_Found,
  Sent_With_Message,
  Payment_API_Error,
  Payment_Is_Duplicate,
  Non_MagneFlex_Browser,
  Browser_Not_Supported,
  Received_Error_Message,
  Transaction_Not_Completed,
  Anywhere_Payment_Not_Enabled,
  Reset_Password_No_UserID
}

export const CaptachErrors = {
  'missing-input-secret': 'The secret parameter is missing',
  'invalid-input-secret':
    'The secret parameter is invalid or malformed',
  'missing-input-response': 'The response parameter is missing',
  'invalid-input-response':
    'The response parameter is invalid or malformed',
  'bad-request': 'The request is invalid or malformed',
  'timeout-or-duplicate':
    'The response is no longer valid: either is too old or has been used previously'
};

export function getErrorMessage(
  type: MessageType,
  toastKey?: string,
  message?: string
) {
  switch (type) {
    case MessageType.API_Request_Failed:
      return {
        severity: 'error',
        summary: 'Request Failed',
        detail:
          'We\'re sorry, something went wrong with your request. Please try again.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Unauthorized_User:
      return {
        severity: 'error',
        summary: 'Not Authorized',
        detail: 'Authorization required, Please Sign in again',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Login_Failed:
      return {
        severity: 'error',
        summary: 'Login Attempt Failed',
        detail:
          'No matching member account found, please verify information and try again.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Search_Username_Error:
      return {
        severity: 'error',
        summary: 'Invalid Email Address',
        detail: 'Invalid email provided. Please enter a valid email address.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.OTP_Sent:
      return {
        severity: 'success',
        summary: 'Success',
        detail:
          'Verification Code has been sent to your registered email address',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.UserRole_Not_Allowed:
      return {
        severity: 'error',
        summary: 'Anywhere Access',
        detail:
          'You do not have access to Anywhere Payments.  Please contact Billhighway Client Care for assistance. ',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.No_Account_Feature_Available:
      return {
        severity: 'error',
        summary: 'Anywhere Access',
        detail:
        'Anywhere transactions are not configured.  Please contact Billhighway Client Care for assistance.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Sent_UserName_Success:
      return {
        severity: 'success',
        summary: 'Sent Successfully',
        detail: 'Username has been sent to your registered email address',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.No_Account_Found:
      return {
        severity: 'error',
        summary: 'Not Available',
        detail:
          'No Linked Accounts are available for this user, please contact Administrator',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Payment_Is_Duplicate:
      return {
        severity: 'error',
        summary: 'Already Processed',
        detail: 'Transaction is Already processed',
        key: toastKey,
        sticky: true,
        closable: true
      };

    case MessageType.No_Chapter_Found:
      return {
        severity: 'error',
        summary: 'Chapters Not Available',
        detail:
          'Your Billhighway account is not associated with any chapters. Please contact Billhighway Client Care for assistance.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Reset_Password_Success:
      return {
        severity: 'success',
        summary: 'Password Reset Successful',
        detail: `Your password has been successfully reset`,
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Non_MagneFlex_Browser:
      return {
        severity: 'error',
        summary: '',
        detail:
          'MagneFlex browser app is required to process Anywhere Credit Card transactions.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Sent_With_Message:
      return {
        severity: 'warn',
        summary: '',
        detail: message,
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Received_Error_Message:
      return {
        severity: 'error',
        summary: '',
        detail: message,
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Payment_API_Error:
      return {
        severity: 'error',
        summary: '',
        detail:
          'The payment failed for this transaction. Please retry the transaction again after a few minutes.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Anywhere_Payment_Not_Enabled:
      return {
        severity: 'error',
        summary: 'Anywhere Payments Not Enabled',
        detail:
          'Anywhere payments not enabled for your organization.  Please contact Billhighway Client Care for assistance.',
        key: toastKey,
        sticky: true,
        closable: true
      };
    case MessageType.Transaction_Not_Completed:
      return {
        key: toastKey,
        sticky: true,
        severity: 'warn',
        summary:
          'Transaction not processed successfully, wish to proceed manually?',
        detail: 'Tap Yes to proceed, Tap No to close'
      };
    case MessageType.Browser_Not_Supported:
      return {
        key: toastKey,
        sticky: true,
        severity: 'warn',
        summary:
          'The MagneFlex browser app is required to accept a credit card. You are attempting a credit card payment using a browser that does not support it. Select Continue to manually enter the credit card, or Cancel to cancel the credit card request.\n Swiping a card requires the MagneFlex browser app, available'
        // detail: 'Tap Yes to proceed, Tap No to close'
      };
    case MessageType.Reset_Password_No_UserID:
      return {
        key: toastKey,
        sticky: true,
        severity: 'error',
        summary:
          'There was an issue processing your request. Please review the account selected and try again'
      };
    default:
      return {
        severity: 'error',
        summary: 'Try again later',
        detail: '',
        key: toastKey,
        sticky: true,
        closable: true
      };
  }
}

export function isSupportedBrowser() {
  const match = navigator.userAgent.search('MAGNEFLEX');
  let isSupported = false;
  if (match !== -1) {
    isSupported = true;
  }
  return isSupported;
}

export function scrollToTop() {
  window.scrollTo(0, 0);
}

export function scrollToBottom() {
  window.scrollTo(0, document.body.scrollHeight);
}

export function getRouteName(featureType: string) {
  switch (featureType) {
    case PaymentType.Donation:
      return 'donate';

    case PaymentType.Deposit:
      return 'deposit';

    case PaymentType.PersonPayment:
      return 'member-payment';
  }
}

export function IsDamagedCard(carddata) {
  if (carddata.indexOf('DFDF300101') !== -1) {
    // var track1len=carddata.indexOf('DFDF300101');
    // var startindex=carddata.indexOf('DFDF30')+'DFDF30'.length;
    // var resultcode=carddata.substring()
    return true;
  } else if (carddata.indexOf('DFDF320101') !== -1) {
    return true;
  } else {
    return false;
  }
}

export function deleteProps(obj) {
  Object.keys(obj).forEach(key => {
    if (!obj[key]) {
      delete obj[key];
    }
  });

  return obj;
}
