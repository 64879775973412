import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  constructor() { }

  isLoading = new Subject<boolean>();
  requestCount = 0;

  show() {
    this.addRequest();
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }

  addRequest() {
    this.requestCount++;
  }

  removeRequest() {
    this.requestCount--;
  }

  getRequestCount() {
    return this.requestCount;
  }
}
