import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UtilService } from 'src/app/shared/services/util.service';
import {
  ApplicationInsightsService,
} from 'src/app/shared/services/appinsight.service';

import { fromEvent, Subscription } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { TransactionService } from '../../services/transaction.service';
import { EmitterService } from '../../../shared/services/emitter.service';
import {
  PaymentData,
  CardInfoData,
  ReferenceIdData,
  OptionsData
} from '../../models/PaymentInfo';
import { PaymentService } from '../../services/payment.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { EventTelemetry } from 'src/app/shared/models/application-insights/event.telemetry.model';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { scrollToTop } from 'src/app/shared/constants';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'manual-card-entry',
  templateUrl: './card-entry.component.html',
  styleUrls: ['./card-entry.component.scss']
})
export class CardEntryComponent implements OnInit, OnDestroy {
  @ViewChild('creditCardInput', { static: true }) creditCardInput: ElementRef;

  type = 'card-entry';
  cardEntryForm: FormGroup;
  submitted = false;
  mask = false;

  months: any;
  years: any;
  formDataTimeout: any;
  paymentData: PaymentData;
  userPaymentData: any;
  currentFeature: string;
  disableSubmit: boolean;

  isCardNameFocus = false;
  isCardNumberFocus = false;
  isSecurityCodeFocus = false;
  isPostalCodeFocus = false;

  transactionFailedSubscription: Subscription;
  constructor(
    private ref: ChangeDetectorRef,
    private utils: UtilService,
    private navigationService: NavigationService,
    private applicationInsightsService: ApplicationInsightsService,
    private transactionService: TransactionService,
    private emitterService: EmitterService,
    private paymentService: PaymentService,
    private storageService: StorageService,
    private errorService: ErrorHandleService
  ) {
    this.cardEntryForm = new FormGroup({
      nameOfCard: new FormControl('', [
        Validators.required,
        Validators.pattern('.*?[^0-9].*'),
        Validators.maxLength(100)
      ]),
      cardNumber: new FormControl('', [
        Validators.required,
	      Validators.pattern(
          '^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})+$'
        ),
        Validators.maxLength(20)
      ]),
      expiryMonth: new FormControl('', Validators.required),
      expiryYear: new FormControl('', Validators.required),
      securityCode: new FormControl('', [
        Validators.required,
        Validators.pattern('^[0-9]{3,4}$')
      ])
    });

    this.months = this.utils.getMonths();
    this.years = this.utils.getValidYears();
  }

  get form() {
    return this.cardEntryForm.controls;
  }

  submitPayment() {
    this.submitted = true;

    if (this.cardEntryForm.valid) {
      this.paymentData = this.preparePaymentData();
      const component = this;
      this.transactionService.DoManualPayment(this.paymentData).subscribe(
        ({ Token }) => {
          this.disableSubmit = true;
          component.applicationInsightsService.trackEvent(
            new EventTelemetry('CardEntry - submitPayment - Token'), { Token: Token });
          this.userPaymentData.TokenId = Token;
          this.paymentService.startPayment(this.currentFeature, true, this.userPaymentData);
        },
        error => {
          this.disableSubmit = false;
          this.errorService.handleErrorResponse(error, 'CardEntry', 'submitPayment');
        }
      );
    }
  }

  setFormDataTimeout(ref) {
    window.clearTimeout(ref);
    this.formDataTimeout = window.setTimeout(() => {
      this.cardEntryForm.reset();
    }, 20 * 60 * 1000); // After 20 Minutes Form will reset
  }

  getContextData(key) {
    return this.storageService.getContext(key);
  }

  ngOnInit() {
    scrollToTop();
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('CardEntryPage'));

    if (!this.userPaymentData) {
      this.navigationService.navigateToDashboard();
    }

    this.cardEntryForm.valueChanges.subscribe(() => {
      this.setFormDataTimeout(this.formDataTimeout);
    });

    fromEvent(this.creditCardInput.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          this.mask = true;
          return event.target.value;
        }),
        debounceTime(1500)
      )
      .subscribe((text: string) => {
        this.mask = false;
      });

    fromEvent(this.creditCardInput.nativeElement, 'focus')
      .pipe(
        map((event: any) => {
          this.mask = true;
          return event.target.value;
        }),
        debounceTime(1500)
      )
      .subscribe((text: string) => {
        this.mask = false;
      });

    this.emitterService.accountFeaturesChange.subscribe(feature => {
      this.currentFeature = feature;
    });

    this.transactionFailedSubscription = this.emitterService.transactionFailed.subscribe(() => {
      this.disableSubmit = false;
      this.ref.detectChanges();
    });
  }

  ngOnDestroy() {
    this.applicationInsightsService.flush();
    this.transactionFailedSubscription.unsubscribe();
  }

  preparePaymentData(): PaymentData {
    const tempPaymentData = new PaymentData();
    tempPaymentData.Description = 'CC payment';
    tempPaymentData.Currency = 'USD';
    tempPaymentData.Amount = this.userPaymentData.Amount;
    const tempCardInfoData = new CardInfoData();

    tempCardInfoData.CardNumber = this.form.cardNumber.value;
    tempCardInfoData.NameOnCard = this.form.nameOfCard.value;
    tempCardInfoData.ExpMonth = this.form.expiryMonth.value;

    tempCardInfoData.ExpYear = this.form.expiryYear.value;
    tempCardInfoData.Cvv = this.form.securityCode.value;
    tempPaymentData.CardInfo = tempCardInfoData;

    const tempRefdata = new ReferenceIdData();
    tempRefdata.Type = 'ChapterId';
    tempRefdata.Value = this.getContextData('ChapId');
    tempPaymentData.ReferenceIds = [];
    tempPaymentData.ReferenceIds.push(tempRefdata);
    tempPaymentData.tokenType = 'standard';

    const tempOptions = new OptionsData();
    tempOptions.Duration = 0;
    tempOptions.Usages = 1;
    tempPaymentData.Options = tempOptions;
    return tempPaymentData;
  }
}
