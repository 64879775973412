import { Subscription, Subject } from 'rxjs';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  PaymentType,
  deleteProps,
  emailRegexPattern,
  emailMaxLength
} from '../../../shared/constants';

import { TransactionService } from '../../services/transaction.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Campaign } from '../../models/Campaign';
import {
  ApplicationInsightsService,
} from 'src/app/shared/services/appinsight.service';
import { ChartOfAccount } from '../../models/ChartOfAccount';
import { CashAccount } from '../../models/CashAccount';
import { PaymentService } from '../../services/payment.service';
import { UserPaymentData } from '../../models/PaymentInfo';
import { DepositPayment } from '../../models/DepositPayment';
import { DonatePayment } from '../../models/DonatePayment';
import { DonorInfo } from '../../models/donorInfo';
import { Member } from '../../models/Member';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { TraceTelemetry } from 'src/app/shared/models/application-insights/trace.telemetry.model';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'feature-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit, OnDestroy {
  depositForm: FormGroup;

  campaigns: Campaign[];
  donorInfo: DonorInfo;
  selectedCampaign: Campaign;
  selectedAccount: CashAccount;
  cashAccountTypes: CashAccount[] = [];
  chartOfAccounts: ChartOfAccount[] = [];

  inputEmail: string;
  otherAmount: number;
  depositAssociationType: string;

  submitted: boolean;
  isGuestDeposit: boolean;
  isMemberDeposit: boolean;
  isManualPayment: boolean;
  isVariableAmount: boolean;
  inputBarClosed: boolean;

  isGetCampaignLoading: boolean;
  isCashAccountsLoading: boolean;
  isCOALoading: boolean;
  isAmountIcon = false;
  isNotes = false;
  isEMVTransactionEnabled = false;

  getCOASubscription: Subscription;
  getCampaignSubscription: Subscription;
  changeChapterSubscription: Subscription;
  getCashAccountsSubscription: Subscription;

  constructor(
    private navigationService: NavigationService,
    private emitterService: EmitterService,
    private transactionService: TransactionService,
    private storageService: StorageService,
    private applicationInsightsService: ApplicationInsightsService,
    private paymentService: PaymentService,
    private errorService: ErrorHandleService
  ) {
    this.depositForm = new FormGroup({
      CoaType: new FormControl(''),
      depositorName: new FormControl(''),
      radioSelect: new FormControl(Number),
      depositAmount: new FormControl('', [
        Validators.required,
        Validators.max(999999.99),
        Validators.min(0.01),
        Validators.pattern(/^\d{0,}(\.\d{1,2})?$/)
      ]),
      shouldSendEmailReceipt: new FormControl(false),
      receiptEmail: new FormControl('',
        [
          Validators.pattern(emailRegexPattern),
          Validators.maxLength(emailMaxLength)
        ]
      ),
      notes: new FormControl(''),
      billhighwayId: new FormControl('')
    });
    this.isEMVTransactionEnabled = this.storageService.getConfigValue('isEMVTransactionEnabled');
  }

  getCashAccounts() {
    this.isCashAccountsLoading = true;
    const userId = this.getContextData('UserId');
    const chapterId = this.getContextData('ChapId');

    this.getCashAccountsSubscription = this.transactionService
      .getCashAccounts(userId, chapterId)
      .subscribe(
        cashAccounts => {
          delete this.selectedAccount;
          this.cashAccountTypes = cashAccounts;
          this.isCashAccountsLoading = false;
        },
        error => {
          this.errorService.handleErrorResponse(error, 'Deposit', 'getCashAccounts');
          this.isCashAccountsLoading = false;
        }
      );
  }

  getChartOfAccounts() {
    this.isCOALoading = true;
    const userId = this.getContextData('UserId');

    this.getCOASubscription = this.transactionService.getChartOfAccounts(userId).subscribe(coaTypes => {
      this.chartOfAccounts = coaTypes;
      this.chartOfAccounts.forEach(({ ChartOfAccountsName, RefId }, index) => {
        this.chartOfAccounts[index].ChartOfAccountsName = RefId + ' – ' + ChartOfAccountsName;
      }
      );
      this.isCOALoading = false;
    }, error => {
      this.errorService.handleErrorResponse(error, 'Deposit', 'getChartOfAccounts');
      this.navigationService.navigateToDashboard();
      this.isCOALoading = false;
    }
    );
  }

  checkAmount(amount) {
    if (!amount) {
      this.form.depositAmount.setValue(this.otherAmount);
      this.isVariableAmount = true;
    } else {
      this.isVariableAmount = false;
      this.form.depositAmount.setValue(amount.Amount);
    }
  }

  resetDepositForm() {
    if (this.chartOfAccounts.length === 0) {
      this.getChartOfAccounts();
    }

    delete this.otherAmount;
    this.form.notes.reset();
    this.form.CoaType.reset();
    this.form.depositAmount.reset();
    this.form.radioSelect.setValue(Number);
    this.submitted = false;
    if (this.form.receiptEmail.invalid) {
      delete this.inputEmail;
      this.form.shouldSendEmailReceipt.setValue(false);
      this.form.receiptEmail.reset();
    }
  }

  get form() {
    return this.depositForm.controls;
  }

  onActivate(componentReference) {
    switch (componentReference.type) {
      case 'member':
        this.isMemberDeposit = true;
        componentReference.selectMember.subscribe((selectedMember: Member) => {
          this.navigationService.navigateToDeposit();

          this.donorInfo = new DonorInfo();
          this.form.depositorName.setValue(selectedMember.MemberName);
          this.donorInfo.Name = selectedMember.MemberName;
          this.donorInfo.Email = selectedMember.EmailAddress;

          this.form.billhighwayId.setValue(selectedMember.BillhighwayAccountNumber);
          this.form.receiptEmail.setValue(selectedMember.EmailAddress);
          this.inputEmail = this.form.receiptEmail.value;
          this.form.shouldSendEmailReceipt.setValue(true);
          this.depositAssociationType = componentReference.type;
          this.isMemberDeposit = false;
          this.inputBarClosed = true;
        });
        break;

      case 'guest':
        this.isGuestDeposit = true;
        componentReference.guestType = 'Deposit';
        componentReference.guestSelect.subscribe((selectedGuest: DonorInfo) => {
          this.navigationService.navigateToDeposit();
          this.donorInfo = deleteProps(selectedGuest);
          this.form.depositorName.setValue(selectedGuest.Name);
          this.form.receiptEmail.setValue(selectedGuest.Email);
          this.inputEmail = this.form.receiptEmail.value;
          this.form.shouldSendEmailReceipt.setValue(true);
          this.depositAssociationType = componentReference.type;
          this.isGuestDeposit = false;
          this.inputBarClosed = true;
        });
        break;

      case 'card-entry':
        this.isManualPayment = true;
        componentReference.userPaymentData = this.depositForm.valid ? this.getUserPaymentData() : false;
        this.emitterService.cardEntryPageOpened(true);
        break;
    }
  }

  onDeactivate() {
    this.isGuestDeposit = false;
    this.isMemberDeposit = false;
    this.isManualPayment = false;
    this.emitterService.cardEntryPageOpened(false);
  }

  getCampaigns() {
    this.isGetCampaignLoading = true;
    this.getCampaignSubscription = this.transactionService.getCampaignList(this.getContextData('ChapId')).subscribe(
      ({ Campaigns }) => {
        delete this.selectedCampaign;
        this.campaigns = Campaigns;
        this.isGetCampaignLoading = false;
      },
      error => {
        this.errorService.handleErrorResponse(error, 'Deposit', 'getCampaigns');
        this.isGetCampaignLoading = false;
      }
    );
  }

  getContextData(key) {
    return this.storageService.getContext(key);
  }

  getUserPaymentData() {
    let userPaymentData = new UserPaymentData();
    userPaymentData.UserId = this.getContextData('UserId');
    userPaymentData.Memo = this.form.notes.value;
    userPaymentData.Amount = Number((this.form.depositAmount.value * 100).toFixed());

    if (this.form.shouldSendEmailReceipt.value) {
      userPaymentData.ReceiptEmail = this.form.receiptEmail.value;
    }

    // If Associate Deposit to Member
    if (this.form.billhighwayId.value) {
      userPaymentData.UserId = this.form.billhighwayId.value;
    } else {
      userPaymentData.UserId = 0;
    }

    if (this.donorInfo) {
      userPaymentData = { ...userPaymentData, ...{ DonorInfo: this.donorInfo } };
    }

    if (this.selectedAccount) {
      const depositPayment = new DepositPayment();
      depositPayment.ChapAcctId = this.selectedAccount.ChapterAccountId;
      depositPayment.ChapBillCatId = this.form.CoaType.value.ChartOfAccountsId;
      userPaymentData = { ...userPaymentData, ...depositPayment };

    } else if (this.selectedCampaign) {
      const donatePayment = new DonatePayment();
      donatePayment.CampaignId = this.selectedCampaign.CampaignId;
      donatePayment.CreateAsDeposit = 'true';
      userPaymentData = { ...userPaymentData, ...donatePayment };
    }

    this.applicationInsightsService.trackTrace(
      new TraceTelemetry('Deposit - GetUserPaymentData - Payment Data'), { UserPaymentData: JSON.stringify(userPaymentData) });

    return userPaymentData;
  }

  handleReceiptEmail() {
    if (!this.depositForm.get('shouldSendEmailReceipt').value) {
      this.form.receiptEmail.setValue('');
    } else {
      this.inputBarClosed = false;
      this.form.receiptEmail.setValue(this.inputEmail);
    }
  }

  depositAmount() {
    if (this.depositForm.get('shouldSendEmailReceipt').value) {
      this.depositForm
        .get('receiptEmail')
        .setValidators([
          Validators.pattern(emailRegexPattern),
          Validators.required,
          Validators.maxLength(emailMaxLength)
        ]);
      this.depositForm.get('receiptEmail').updateValueAndValidity();
    } else {
      this.depositForm.get('receiptEmail').setValidators([
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]);
      this.depositForm.get('receiptEmail').updateValueAndValidity();
    }
    this.submitted = true;



    if (this.depositForm.valid && this.paymentService.validateBrowser()) {
      if (this.isEMVTransactionEnabled) {
        this.applicationInsightsService.trackTrace(
          new TraceTelemetry('Deposit - DepositAmount - IsEmvEnabled'), { IsEmvEnabled: JSON.stringify(this.isEMVTransactionEnabled) });
        this.paymentService.startEMVTransaction(PaymentType.Deposit, false, this.getUserPaymentData());

      }
      else {
        const paymentType = this.selectedCampaign ? PaymentType.Donation : PaymentType.Deposit;
        this.paymentService.startTransaction(paymentType, false, this.getUserPaymentData());
      }


    }
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('DepositPage'));
    this.form.radioSelect.setValue(this.form.depositAmount.value);
    this.changeChapterSubscription = this.emitterService.chapterChange.subscribe(
      () => {
        this.depositAssociationType = '';
        this.depositForm.reset();
        this.getCashAccounts();
        this.getCampaigns();
      }
    );
  }

  ngOnDestroy(): void {
    this.getCampaignSubscription.unsubscribe();
    this.changeChapterSubscription.unsubscribe();
    this.getCashAccountsSubscription.unsubscribe();
  }
}
