import { Injectable } from '@angular/core';
import { ApiService, APIMethod } from 'src/app/shared/services/api.service';
import { APIRequest } from 'src/app/shared/models/api.request.model';
import { map } from 'rxjs/operators';
import { Member } from '../models/Member';
import { PaymentData } from '../models/PaymentInfo';
import { PaymentType } from 'src/app/shared/constants';
import { StorageService } from 'src/app/shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  paymentChargeApiUrl:string;

  constructor(private apiService: ApiService, private storageService: StorageService,) {
    this.paymentChargeApiUrl = this.storageService.getConfigValue('paymentChargeApi');
  }

  public getUserLinkedAccounts(userName: string) {
    const apiRequest: APIRequest = new APIRequest(
      `LoginLinkedAccounts/${userName}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public getMemberBalances(userId: number, billhighwayId: number ) {
    const apiRequest: APIRequest = new APIRequest(
      `MemberBalance/${userId}/${billhighwayId}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public getMemberPaymentInvoices(userId: number, billhighwayId: number, option: string) {
    const apiRequest: APIRequest = new APIRequest(
      `MemberInvoices/${userId}/${billhighwayId}/${option}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public searchMembers(memberInfo: Member) {
    const apiRequest: APIRequest = new APIRequest(
      `Members/${memberInfo.NationalId}/${memberInfo.ChapterId}/${memberInfo.RequestingUserId}`
        + ((memberInfo.MemberName || memberInfo.EmailAddress || memberInfo.BillHighwayId || memberInfo.ExternalId) ? '?' : '')
        + (!memberInfo.MemberName ? '' : `name=${memberInfo.MemberName}`)
        + (!memberInfo.EmailAddress ? '' : `&email=${memberInfo.EmailAddress}`)
        + (!memberInfo.BillHighwayId ? '' : `&userId=${memberInfo.BillHighwayId}`)
        + (!memberInfo.ExternalId ? '' :  `&externalId=${memberInfo.ExternalId}`),
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public getCampaignList(chapterId: number) {
    const apiRequest: APIRequest = new APIRequest(
      `Campaigns/${chapterId}?showOnAnywhere=1`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public changeLinkedAccount(targetUserId: number) {
    const apiRequest: APIRequest = new APIRequest(
      `LoginChangeAccount`,
      APIMethod.POST, {
            TargetUserId : targetUserId
      }
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public getCashAccounts(userId: number, chapterId: number) {
    const apiRequest: APIRequest = new APIRequest(
      `CashAccounts/${userId}/${chapterId}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public getChartOfAccounts(userId: number) {
    const apiRequest: APIRequest = new APIRequest(
      `ChartOfAccounts/${userId}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public DoManualPayment(paymentdata: PaymentData) {
    const apiRequest: APIRequest = new APIRequest(
      '',
      APIMethod.POST,
      paymentdata, this.paymentChargeApiUrl, PaymentType.ChargeToken);

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }
}
