import { Injectable, Optional, Injector } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ITelemetryContext,
  IEventTelemetry,
  IPageViewTelemetry,
  IMetricTelemetry,
  ITraceTelemetry,
  IDependencyTelemetry
} from '@microsoft/applicationinsights-common';
import { environment } from 'src/environments/environment';
import { ExceptionTelemetry } from '../models/application-insights/exception.telemetry.model';
import {
  Router,
  ActivatedRouteSnapshot
} from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

// Since AI.SeverityLevel isn't working we can just use our own
export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4
}

export class AppInsightsConfig {
  instrumentationKeySetLater?: boolean;
  instrumentationKey?: string;
  endpointUrl?: string;
  emitLineDelimitedJson?: boolean;
  accountId?: string;
  sessionRenewalMs?: number;
  sessionExpirationMs?: number;
  maxBatchSizeInBytes?: number;
  maxBatchInterval?: number;
  enableDebug?: boolean;
  disableExceptionTracking?: boolean;
  disableTelemetry?: boolean;
  verboseLogging?: boolean;
  diagnosticLogInterval?: number;
  samplingPercentage?: number;
  autoTrackPageVisitTime?: boolean;
  disableAjaxTracking?: boolean;
  overridePageViewDuration?: boolean;
  maxAjaxCallsPerView?: number;
  disableDataLossAnalysis?: boolean;
  disableCorrelationHeaders?: boolean;
  correlationHeaderExcludedDomains?: string[];
  disableFlushOnBeforeUnload?: boolean;
  enableSessionStorageBuffer?: boolean;
  isCookieUseDisabled?: boolean;
  cookieDomain?: string;
  isRetryDisabled?: boolean;
  url?: string;
  isStorageUseDisabled?: boolean;
  isBeaconApiDisabled?: boolean;
  sdkExtension?: string;
  isBrowserLinkTrackingEnabled?: boolean;
  appId?: string;
  enableCorsCorrelation?: boolean;
  overrideTrackPageMetrics?: boolean;
}

 let appInsights: ApplicationInsights;

@Injectable()
export class ApplicationInsightsService {
  get context(): ITelemetryContext {
    return appInsights.context;
  }
  get queue(): Array<() => void> {
    return appInsights.appInsights.queue;
  }
  config: AppInsightsConfig;

  constructor(
    @Optional() _config: AppInsightsConfig,
    private _injector: Injector
  ) {
    this.config = _config;
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackevent
  /**
   * Log a user action or other occurrence.
   * @param   event    An event in the portal.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   */
  trackEvent(
    event: IEventTelemetry,
    eventProperties?: { [name: string]: string }
  ) {
    try {
      if (environment.appInsightsConfig.events) {
        appInsights.trackEvent(event, eventProperties);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackEvent]: ', ex);
    }
  }

  /**
   * Start timing an extended event. Call {@link stopTrackEvent} to log the event when it ends.
   * @param   name    A string that identifies this event uniquely within the document.
   */
  startTrackEvent(name: string): any {
    try {
      if (environment.appInsightsConfig.events) {
        appInsights.startTrackEvent(name);
      }
    } catch (ex) {
      console.warn(
        'Angular application insights Error [startTrackEvent]: ',
        ex
      );
    }
  }

  /**
   * Log an extended event that you started timing with {@link startTrackEvent}.
   * @param   name    The string you used to identify this event in startTrackEvent.
   * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  stopTrackEvent(
    name: string,
    properties?: { [p: string]: string },
    measurements?: { [p: string]: number }
  ): any {
    try {
      if (environment.appInsightsConfig.events) {
        appInsights.stopTrackEvent(name, properties, measurements);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [stopTrackEvent]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackpageview
  /**
   * Logs that a page or other item was viewed.
   * @param   pageViewTelemetry  The telemetry of the page.
   */
  trackPageView(pageViewTelemetry?: IPageViewTelemetry) {
    try {
      if (environment.appInsightsConfig.pageviews) {
        appInsights.trackPageView(pageViewTelemetry);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackPageView]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#starttrackpage
  /**
   * Starts timing how long the user views a page or other item. Call this when the page opens.
   * This method doesn't send any telemetry. Call {@link stopTrackTelemetry} to log the page when it closes.
   * @param   name  A string that idenfities this item, unique within this HTML document. Defaults to the document title.
   */
  startTrackPage(name?: string) {
    try {
      if (environment.appInsightsConfig.pageviews) {
        appInsights.startTrackPage(name);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [startTrackPage]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#stoptrackpage
  /**
   * Logs how long a page or other item was visible, after {@link startTrackPage}. Call this when the page closes.
   * @param   name  The string you used as the name in startTrackPage. Defaults to the document title.
   * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
   * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
   */
  stopTrackPage(
    name?: string,
    url?: string,
    properties?: { [name: string]: string },
    measurements?: { [name: string]: number }
  ) {
    try {
      if (environment.appInsightsConfig.pageviews) {
        appInsights.stopTrackPage(name, url, properties, measurements);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [stopTrackPage]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackmetric
  /**
   * Log a numeric value that is not associated with a specific event. Typically used to send regular reports of performance indicators.
   * To send a single measurement, use just the first two parameters. If you take measurements very frequently, you can reduce the
   * telemetry bandwidth by aggregating multiple measurements and sending the resulting average at intervals.
   * @param   metricTelemetry    The telemetry of the metric.
   * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
   */
  trackMetric(
    metricTelemetry: IMetricTelemetry,
    properties?: { [name: string]: string }
  ) {
    try {
      appInsights.trackMetric(metricTelemetry, properties);
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackexception
  /**
   * Log an exception you have caught.
   * @param   exception   An Error from a catch clause, or the string error message.
   */
  trackException(
    exception: any,
    severityLevel?: SeverityLevel | SeverityLevel.Error
  ) {
    try {
      if(exception instanceof HttpErrorResponse) {
        let errorMessage = '';
        if(exception.error && exception.error.Messages)
          errorMessage = JSON.stringify(exception.error.Messages);
        exception = new ExceptionTelemetry(new Error(exception.message + ' \n' + errorMessage), SeverityLevel.Error);
      }
      if (environment.appInsightsConfig.exceptions) {
        appInsights.trackException(exception);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackException]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#tracktrace
  // trackTrace(message: string, properties?: {[string]:string}, severityLevel?: SeverityLevel | AI.SeverityLevel)
  // Log a diagnostic event such as entering or leaving a method.
  /**
   * Log a diagnostic message.
   * @param    trace A trace
   * @param    properties  map[string, string] - additional data used to filter traces in the portal. Defaults to empty.
   */
  trackTrace(trace: ITraceTelemetry, properties?: { [name: string]: string }) {
    try {
      if (environment.appInsightsConfig.traces) {
        appInsights.trackTrace(trace, properties);
      }
    } catch (ex) {
      console.warn('Angular application insights Error [trackTrace]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#trackdependency
  /**
   * Log a dependency call (for instance: ajax)
   * @param   dependency    A dependency telemetry.
   */
  trackDependency(dependency: IDependencyTelemetry) {
    try {
      if (environment.appInsightsConfig.dependencies) {
        appInsights.trackDependencyData(dependency);
      }
    } catch (ex) {
      console.warn(
        'Angular application insights Error [trackDependency]: ',
        ex
      );
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#flush
  // flush()
  // Immediately send all queued telemetry. Synchronous.
  // * You don't usually have to use this, as it happens automatically on window closing.
  flush(async?: boolean | true) {
    try {
      appInsights.flush(async);
    } catch (ex) {
      console.warn('Angular application insights Error [flush]: ', ex);
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
  /**
   * Sets the authenticated user id and the account id.
   * User auth id and account id should be of type string. They should not contain commas, semi-colons, equal signs, spaces, or vertical-bars.
   *
   * By default the method will only set the authUserID and accountId for all events in this page view. To add them to all events within
   * the whole session, you should either call this method on every page view or set `storeInCookie = true`.
   *
   * @param authenticatedUserId {string} - The authenticated user id. A unique and persistent string that represents each authenticated user in the service.
   * @param accountId {string} - An optional string to represent the account associated with the authenticated user.
   * @param storeInCookie {boolean} - AuthenticateUserID will be stored in a cookie and added to all events within this session.
   */
  setAuthenticatedUserContext(
    authenticatedUserId: string,
    accountId?: string,
    storeInCookie: boolean = false
  ) {
    try {
      appInsights.setAuthenticatedUserContext(
        authenticatedUserId,
        accountId,
        storeInCookie
      );
    } catch (ex) {
      console.warn(
        'Angular application insights Error [setAuthenticatedUserContext]: ',
        ex
      );
    }
  }

  // https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md#clearauthenticatedusercontext
  /**
   * Clears the authenticated user id and the account id from the user context.
   */
  clearAuthenticatedUserContext() {
    try {
      appInsights.clearAuthenticatedUserContext();
    } catch (ex) {
      console.warn(
        'Angular application insights Error [clearAuthenticatedUserContext]: ',
        ex
      );
    }
  }

  _onerror(message: string): any {
    console.warn('Angular application insights Error [_onerror]: ', message);
  }

  /**
   * Initialize Application Insights for Angular
   * Make sure your config{} has been set
   */
  public init(): void {
    appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsightsConfig.instrumentationKey,
          /* ...Other Configuration Options... */
        }
      });
    appInsights.loadAppInsights();
  }

  private get router() {
    try {
      return this._injector.get(Router);
    } catch (ex) {
      // @angular/router is not included - App must be utilizing UIRouter
      return null;
    }
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }
  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }
    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }

}
