import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, RouterEvent } from '@angular/router';
import { EmitterService } from '../../services/emitter.service';
import { StorageService } from '../../services/storage.service';
import { LoginService } from 'src/app/login/login.service';
import { MessageService } from 'primeng/api';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'page-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  copyrightYear: number;
  currentPage: string;
  displayRedirectModal = false;
  redirectLink = '';
  isLoggedIn: boolean;
  errorPage: boolean;

  constructor(
    private router: Router,
    private emitterService: EmitterService,
    private storageService: StorageService,
    private loginService: LoginService,
    private messageService: MessageService,
    private authService: AuthService
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.currentPage = event.url.split('/').splice(1, 2).toLocaleString();

        if (this.currentPage !== '' && this.currentPage !== 'login') {
          this.currentPage = 'other';
        }
      }
    });
  }

  ngOnInit() {
    this.emitterService.errorPage.subscribe((response) => {
      this.errorPage = response;
    });
    this.isLoggedIn = this.storageService.isLoggedIn();
    this.copyrightYear = new Date().getFullYear();
  }

  externalRedirect(event, link) {
    this.isLoggedIn = this.storageService.isLoggedIn();
    if (link) {
      event.preventDefault();
      this.displayRedirectModal = true;
      this.redirectLink = link;
    } else {
      window.location.href = link;
    }
  }

  redirectAction() {
    this.displayRedirectModal = false;
    this.isLoggedIn = this.storageService.isLoggedIn();

    if (this.isLoggedIn) {
      this.authService.logout().subscribe(() =>{
        window.location.href = this.redirectLink;
      });
    } else {
      window.location.href = this.redirectLink;
    }
  }
}
