import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiInsightsError } from '../models/appInsights.error.model';
declare const moment: any;

@Injectable()
export class UtilService {
  constructor() {}

  public isEmpty(data: any): boolean {
    if (data instanceof Array) {
      return data === null || data === undefined || data.length === 0;
    } else {
      return data === null || data === undefined || data === '';
    }
  }

  dateToString(date: Date): string {
    if (this.isEmpty(date)) {
      return '';
    }
    const d = new Date(date);
    const mm = d.getMonth() + 1; // getMonth() is zero-based
    const dd = d.getDate();

    return [
      d.getFullYear(),
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd
    ].join('-');
  }

  validateNumber(event, value, maxLength) {
    if (!this.isEmpty(value) && event.keyCode !== 8) {
      if (value.toString().indexOf('.') > -1) {
        const part = value.toString().split('.');

        if (part[0].length >= maxLength) {
          return false;
        }
      } else {
        if (value.toString().length >= maxLength && event.keyCode !== 190) {
          return false;
        }
      }
    }

    if (
      event.keyCode === 69 ||
      event.keyCode === 107 ||
      event.keyCode === 109 ||
      event.keyCode === 187 ||
      event.keyCode === 189
    ) {
      return false;
    }
  }

  compareNumber(event, value, maxvalue) {
    if (!this.isEmpty(value) && event.keyCode !== 8) {
      if (value > maxvalue) {
        return false;
      }
    }

    if (
      event.keyCode === 69 ||
      event.keyCode === 107 ||
      event.keyCode === 109 ||
      event.keyCode === 187 ||
      event.keyCode === 189
    ) {
      return false;
    }
  }

  validateInteger(event, value, maxLength) {
    const newLocal = this;
    if (!newLocal.isEmpty(value) && event.keyCode !== 8) {
      if (value.toString().length >= maxLength) {
        return false;
      }
    }

    if (
      event.keyCode === 69 ||
      event.keyCode === 107 ||
      event.keyCode === 110 ||
      event.keyCode === 187 ||
      event.keyCode === 190
    ) {
      return false;
    }
  }
  public getCurrentFiscalYear() {
    const d = new Date();
    let fiscalYear;
    if (d.getMonth() >= 6) {
      fiscalYear = d.getFullYear() + 1;
    } else {
      fiscalYear = d.getFullYear();
    }
    return fiscalYear;
  }

  public getMonths() {
    return [
      { Description: 'January', label: '01', value: 1 },
      { Description: 'February', label: '02', value: 2 },
      { Description: 'March', label: '03', value: 3 },
      { Description: 'April', label: '04', value: 4 },
      { Description: 'May', label: '05', value: 5 },
      { Description: 'June', label: '06', value: 6 },
      { Description: 'July', label: '07', value: 7 },
      { Description: 'August', label: '08', value: 8 },
      { Description: 'September', label: '09', value: 9 },
      { Description: 'October', label: '10', value: 10 },
      { Description: 'November', label: '11', value: 11 },
      { Description: 'December', label: '12', value: 12 }
    ];
  }

  getValidYears() {
    const validYears = [];

    for (let currYear = 0; currYear <= 12; currYear++) {
      const currentYear = moment()
        .add(currYear, 'years')
        .format('YY');
      validYears.push({
        label: currentYear,
        value: moment(currentYear, 'YY').format('YYYY')
      });
    }
    return validYears;
  }

  public getFiscalHalf() {
    return [
      { Month: 1, FiscalHalf: 'Back Half' },
      { Month: 2, FiscalHalf: 'Back Half' },
      { Month: 3, FiscalHalf: 'Back Half' },
      { Month: 4, FiscalHalf: 'Back Half' },
      { Month: 5, FiscalHalf: 'Back Half' },
      { Month: 6, FiscalHalf: 'Back Half' },
      { Month: 7, FiscalHalf: 'Front Half' },
      { Month: 8, FiscalHalf: 'Front Half' },
      { Month: 9, FiscalHalf: 'Front Half' },
      { Month: 10, FiscalHalf: 'Front Half' },
      { Month: 11, FiscalHalf: 'Front Half' },
      { Month: 12, FiscalHalf: 'Front Half' }
    ];
  }

  public getStates() {
    return [
      { value: 'AK', label: 'Alaska'},
      { value: 'AS', label: 'American Samoa'},
      { value: 'AZ', label: 'Arizona'},
      { value: 'AR', label: 'Arkansas'},
      { value: 'CA', label: 'California'},
      { value: 'CO', label: 'Colorado'},
      { value: 'CT', label: 'Connecticut'},
      { value: 'DE', label: 'Delaware'},
      { value: 'DC', label: 'District of Columbia'},
      { value: 'FM', label: 'Federated States of Micronesia'},
      { value: 'FL', label: 'Florida'},
      { value: 'GA', label: 'Georgia'},
      { value: 'GU', label: 'Guam'},
      { value: 'HI', label: 'Hawaii'},
      { value: 'ID', label: 'Idaho'},
      { value: 'IL', label: 'Illinois'},
      { value: 'IN', label: 'Indiana'},
      { value: 'IA', label: 'Iowa'},
      { value: 'KS', label: 'Kansas'},
      { value: 'KY', label: 'Kentucky'},
      { value: 'LA', label: 'Louisiana'},
      { value: 'ME', label: 'Maine'},
      { value: 'MH', label: 'Marshall Islands'},
      { value: 'MD', label: 'Maryland'},
      { value: 'MA', label: 'Massachusetts'},
      { value: 'MI', label: 'Michigan'},
      { value: 'MN', label: 'Minnesota'},
      { value: 'MS', label: 'Mississippi'},
      { value: 'MO', label: 'Missouri'},
      { value: 'MT', label: 'Montana'},
      { value: 'NE', label: 'Nebraska'},
      { value: 'NV', label: 'Nevada'},
      { value: 'NH', label: 'New Hampshire'},
      { value: 'NJ', label: 'New Jersey'},
      { value: 'NM', label: 'New Mexico'},
      { value: 'NY', label: 'New York'},
      { value: 'NC', label: 'North Carolina'},
      { value: 'ND', label: 'North Dakota'},
      { value: 'MP', label: 'Northern Mariana Islands'},
      { value: 'OH', label: 'Ohio'},
      { value: 'OK', label: 'Oklahoma'},
      { value: 'OR', label: 'Oregon'},
      { value: 'PW', label: 'Palau'},
      { value: 'PA', label: 'Pennsylvania'},
      { value: 'PR', label: 'Puerto Rico'},
      { value: 'RI', label: 'Rhode Island'},
      { value: 'SC', label: 'South Carolina'},
      { value: 'SD', label: 'South Dakota'},
      { value: 'TN', label: 'Tennessee'},
      { value: 'TX', label: 'Texas'},
      { value: 'UT', label: 'Utah'},
      { value: 'VT', label: 'Vermont'},
      { value: 'VI', label: 'Virgin Islands'},
      { value: 'VA', label: 'Virginia'},
      { value: 'WA', label: 'Washington'},
      { value: 'WV', label: 'West Virginia'},
      { value: 'WI', label: 'Wisconsin'},
      { value: 'WY', label: 'Wyoming'},
      { value: 'AL', label: 'Alabama'},
      { value: 'AA', label: 'Armed Forces Americas'},
      { value: 'AE', label: 'Armed Forces Europe'},
      { value: 'AP', label: 'Armed Forces Pacific'}
    ];
  }

  public daysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
  }

  private getAllFiscalQuarters() {
    return [
      { Text: 'All', value: 'All' },
      { Text: 'Quarter 1', value: 'Q1' },
      { Text: 'Quarter 2', value: 'Q2' },
      { Text: 'Quarter 3', value: 'Q3' },
      { Text: 'Quarter 4', value: 'Q4' }
    ];
  }

  public checkDateString(date: string) {
    const temp = new Date(date);
    if (temp.getFullYear() === 1) {
      return false;
    }
    return true;
  }

  getFiscalYearList(MinFiscalYear: number) {
    const fiscalYearList: any[] = [];
    let minFiscalYear = MinFiscalYear;
    if (
      minFiscalYear <= 0 ||
      this.isEmpty(minFiscalYear) ||
      minFiscalYear >= this.getCurrentFiscalYear()
    ) {
      minFiscalYear = this.getCurrentFiscalYear() - 1;
    }

    for (let i = minFiscalYear; i <= this.getCurrentFiscalYear() + 1; i++) {
      fiscalYearList.push({ Text: i.toString(), value: i });
    }
    return fiscalYearList;
  }

  getFiscalYearListWithAll(MinFiscalYear: number) {
    const fiscalYearList: any[] = [];
    let minFiscalYear = MinFiscalYear;
    if (
      minFiscalYear <= 0 ||
      this.isEmpty(minFiscalYear) ||
      minFiscalYear >= this.getCurrentFiscalYear()
    ) {
      minFiscalYear = this.getCurrentFiscalYear() - 1;
    }

    fiscalYearList.push({ Text: 'All', value: 'All' });
    for (let i = minFiscalYear; i <= this.getCurrentFiscalYear() + 1; i++) {
      fiscalYearList.push({ Text: i.toString(), value: i });
    }
    return fiscalYearList;
  }

  jwtEncode(input) {
    if (!input) { return input; }
    const data: string = this.base64urlEncode(JSON.stringify(input));
    const header: string = btoa(
      JSON.stringify({
        typ: 'JWT',
        alg: environment.encode.algorithm
      })
    );
    const token = header + '.' + data;
    return token;
  }

  jwtDecode(input) {
    if (!input) { return input; }
    let base64Url = input.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  base64urlEncode(str) {
    return this.base64urlEscape(btoa(str));
  }

  base64urlEscape(str) {
    return str
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
  }

  prepareAppInsightError(
    error: any,
    errorMethod: string,
    errorPath: string,
    properties?: { [name: string]: string } | []
  ) {
    return new ApiInsightsError(
      error.hasOwnProperty('error') ? error.error : JSON.stringify(error),
      error.hasOwnProperty('name')
        ? error.name + error.message
        : JSON.stringify(error),
      errorMethod,
      errorPath,
      JSON.stringify(properties)
    );
  }
}
