import { Injectable, NgZone } from '@angular/core';
import { ApiService, APIMethod } from 'src/app/shared/services/api.service';
import { APIRequest } from 'src/app/shared/models/api.request.model';
import { map } from 'rxjs/operators';
import { UserProfileData } from '../models/PaymentInfo';
import {
  PaymentType,
  IsDamagedCard,
  scrollToTop,
  getErrorMessage,
  MessageType,
  isSupportedBrowser
} from 'src/app/shared/constants';
import { StorageService } from 'src/app/shared/services/storage.service';
import { PaymentConfirmation } from '../models/PaymentConfirmation';
import { MessageService } from 'primeng/api';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { ApplicationInsightsService, SeverityLevel } from 'src/app/shared/services/appinsight.service';
import { TraceTelemetry } from 'src/app/shared/models/application-insights/trace.telemetry.model';
import { EventTelemetry } from 'src/app/shared/models/application-insights/event.telemetry.model';

declare const MTMagneFlexLib: any;
declare const MTMagneFlexParameter: any;
declare const MagneFlexOp: any;
declare const HTTPMethod: any;
declare const MTMagneFlexHelper: any;

@Injectable({
  providedIn: 'root'
})

export class PaymentService {
  private apiRequest: APIRequest;
  private paymentURL: string;
  private paymentType: string;
  private userPaymentData: any;
  private isManualPayment: boolean;
  private supportedCardType: string;
  private cardReaderSwipeConfigData:any;
  private cardReaderEmvConfigData:any;

  constructor(
    private ngZone: NgZone,
    private apiService: ApiService,
    private messageService: MessageService,
    private emitterService: EmitterService,
    private storageService: StorageService,
    private appInsightService: ApplicationInsightsService,
    private navigationService: NavigationService
  ) {
    this.cardSwipeCallBack = this.cardSwipeCallBack.bind(this);
    this.emvCardbatchDataCallback = this.emvCardbatchDataCallback.bind(this);
    this.supportedCardType = this.storageService.getConfigValue('supportCardType');
    this.cardReaderSwipeConfigData = this.storageService.getConfigValue('cardReaderSwipeConfigData');
    this.cardReaderEmvConfigData = this.storageService.getConfigValue('cardReaderEMVConfigData');
  }

  public processPayment(paymentType: string, isManualPayment: boolean, payLoad: any) {
    const userData = this.getUserData();

    if (paymentType !== PaymentType.PersonPayment && payLoad.CreateAsDeposit) {
      paymentType = PaymentType.Donation;
    }

    switch (paymentType) {
      case PaymentType.Deposit:
        this.paymentURL = isManualPayment ? 'TokenDeposit' : `InPersonDeposit`;
        break;

      case PaymentType.Donation:
        this.paymentURL = isManualPayment ? 'TokenDonation' : 'InPersonDonation';
        break;

      case PaymentType.PersonPayment:
        this.paymentURL = isManualPayment ? 'TokenPayment' : 'InPersonPayment';
        break;
    }

    this.appInsightService.trackTrace(new TraceTelemetry('PaymentAPIRequest'), { RequestData: JSON.stringify(this.apiRequest) } );

    this.apiRequest = new APIRequest(`${this.paymentURL}/${userData.ChapterId}/${userData.RequestingUserId}`,
      APIMethod.POST, payLoad, '', PaymentType.CardPayment);

    return this.processRequest(this.apiRequest);
  }

  private processRequest(request: APIRequest) {
    return this.apiService.executeAPI(request).pipe(
      map(response => {
        return response;
      })
    );
  }

  startTransaction(accountFeature, isManualPayment, userPaymentData: any) {
    this.appInsightService.trackTrace(new TraceTelemetry(`${accountFeature} - StartTransaction - UserPaymentData`),
      { UserPaymentData: JSON.stringify(userPaymentData) }
    );

    const readerParam = new MTMagneFlexParameter.readerParameter();
    readerParam.timeLimit = this.cardReaderSwipeConfigData.timeLimit;
    const requestParam = new MTMagneFlexParameter.requestParameter();
    requestParam.operation = MagneFlexOp.REQUEST_CARD_SWIPE;
    requestParam.customDisplayMessage = this.cardReaderSwipeConfigData.customDisplayMessage;
    requestParam.httpMethod = HTTPMethod.NONE;
    requestParam.fullScreenMode = false;
    requestParam.closeDeviceAfter = false;
    requestParam.destinationURL = '';

    this.paymentType = accountFeature;
    this.isManualPayment = isManualPayment;
    this.userPaymentData = userPaymentData;

    MTMagneFlexLib.requestCardSwipe(
      readerParam,
      requestParam,
      this.cardSwipeCallBack
    );
  }

  cardSwipeCallBack(cardData) {
    if (cardData) {
      this.appInsightService.trackTrace(new TraceTelemetry(`${this.paymentType} - CardSwipeCallBack - CardData`),
        { CardData: cardData.toString() }
      );
      const magneFlexHelper = JSON.stringify(MTMagneFlexHelper.parseQueryString(cardData));
      const obj = JSON.parse(magneFlexHelper); // magneFlexHelper;

      if (obj.errorCode !== '0') {
        this.appInsightService.trackEvent(new EventTelemetry('CardReaderFailed'), { ErrorMessage: 'Card Reader Error' });

        this.appInsightService.trackTrace(new TraceTelemetry(`${this.paymentType} - CardSwipeCallBack - CardDataInvalid`),
          { ErrorMessage: obj.errorCode.toString() });

        this.emitterService.triggerTransactionFailed();
        this.showConfirm();
      } else {
        const cardDataIn = obj.CardDataIn;
        const isCardDamaged = IsDamagedCard(cardDataIn);;
        if (isCardDamaged) {
          this.showConfirm();
          this.appInsightService.trackEvent(new EventTelemetry('CardReaderFailed'), { ErrorMessage: 'Damaged Card' });
          this.emitterService.triggerTransactionFailed();
        } else {
          this.startPayment(this.paymentType, this.isManualPayment, this.userPaymentData, cardDataIn);
        }
      }
    } else {
      this.showConfirm();
    }
  }

  showConfirm() {
    this.messageService.add(getErrorMessage(MessageType.Transaction_Not_Completed, 'PaymentConfirmMessage'));
  }

  startPayment(paymentType, isManualPayment, userPaymentData, cardSwipeData?: any) {
    this.appInsightService.trackEvent(new EventTelemetry('PaymentInitiated'), { Operation: `${isManualPayment ? 'Manual' : 'Card'} - ${paymentType}` });

    if (!isManualPayment) {
      userPaymentData.EncryptedPaymentData = cardSwipeData;
      this.appInsightService.trackTrace(new TraceTelemetry(`${paymentType} - StartPayment - CardSwipeData`),
      { CardSwipeData: JSON.stringify(cardSwipeData) });
    }

    this.appInsightService.trackTrace(
      new TraceTelemetry(`${paymentType} - StartPayment - UserPaymentData`),
      { UserPaymentData: JSON.stringify(userPaymentData) }
    );

    this.processPayment(paymentType, isManualPayment, userPaymentData).subscribe(
      (paymentConfirmationData: PaymentConfirmation) => {
        if (paymentConfirmationData.IsDuplicate) {
          this.emitterService.triggerTransactionFailed();
          this.messageService.add(getErrorMessage(MessageType.Payment_Is_Duplicate, 'PaymentMessage'));
        } else if (paymentConfirmationData.Success) {
          this.appInsightService.trackEvent(new EventTelemetry('PaymentCompleted'),
            { Operation: `${isManualPayment ? 'Manual' : 'Card'} - ${paymentType}`, Amount: userPaymentData.Amount });

          this.appInsightService.trackTrace(
            new TraceTelemetry(`${paymentType} - ProcessPayment - PaymentConfirmationData`),
            { PaymentConfirmationData: JSON.stringify(paymentConfirmationData) }
          );
          scrollToTop();
          this.emitterService.transactionConfirmationDone(paymentConfirmationData);
          this.ngZone.run(() => this.navigationService.navigateToConfirmation());
        } else {
          this.appInsightService.trackEvent(new EventTelemetry('PaymentFailed'), { Operation: `${isManualPayment ? 'Manual' : 'Card'} - ${paymentType}` });

          this.emitterService.triggerTransactionFailed();
          this.appInsightService.trackTrace(
            new TraceTelemetry(`${paymentType} - ProcessPayment - PaymentNotProcessed`),
            { PaymentConfirmationData: JSON.stringify(paymentConfirmationData) }
          );
          this.ngZone.run(() =>
            this.messageService.add(getErrorMessage(MessageType.Sent_With_Message, 'PaymentMessage', paymentConfirmationData.Message))
          );
        }
      },
      paymentError => {
        this.appInsightService.trackEvent(new EventTelemetry('PaymentFailed'), { Operation: `${isManualPayment ? 'Manual' : 'Card'} - ${paymentType}` });

        this.emitterService.triggerTransactionFailed();
        this.appInsightService.trackTrace(new TraceTelemetry(`${paymentType} - ProcessPayment - PaymentErrorData`),
          { ErrorMessage: JSON.stringify(paymentError) }
        );

        this.appInsightService.trackException(paymentError, SeverityLevel.Error);

        const errorData = paymentError.error;
        if (errorData && errorData.hasOwnProperty('Messages')) {
          errorData.Messages.forEach(error => {
            this.messageService.add(getErrorMessage(MessageType.Received_Error_Message, 'PaymentMessage', error.Message));
          });
        } else {
          this.messageService.add(getErrorMessage(MessageType.Payment_API_Error, 'PaymentMessage'));
        }
      }
    );
  }

  showNonMagnaflexConfirm() {
    this.messageService.add(getErrorMessage(MessageType.Browser_Not_Supported, 'BrowserNotSupported'));
  }

  validateBrowser() {
    let tempSupportedBrowser = true;
    tempSupportedBrowser = isSupportedBrowser();
    if (tempSupportedBrowser === false) {
      this.showNonMagnaflexConfirm();
      return tempSupportedBrowser;
    } else {
      return tempSupportedBrowser;
    }
  }

  getUserData() {
    const userData = new UserProfileData();
    userData.ChapterId = this.getData('ChapId');
    userData.RequestingUserId = this.getData('UserId');
    return userData;
  }

  getData(key) {
    return this.storageService.getContext(key);
  }

  emvCardarcqDataCallBack(data) {
  }

  emvCardbatchDataCallback(cardData) {
    if (cardData) {
      this.appInsightService.trackTrace(new TraceTelemetry(`${this.paymentType} - EmvCardBatchDataCallback - CardData`),
        { CardData: cardData.toString() }
      );
      const magneFlexHelper = JSON.stringify(MTMagneFlexHelper.parseQueryString(cardData));
      const obj = JSON.parse(magneFlexHelper);

      if (obj.errorCode !== '0') {
        this.appInsightService.trackEvent(new EventTelemetry('CardReaderFailed'), { Message: 'Card Reader Error' });
        this.emitterService.triggerTransactionFailed();
        this.showConfirm();
      } else {
        const cardDataIn = obj.batchData;
        this.startPayment(this.paymentType, this.isManualPayment, this.userPaymentData, cardDataIn);
      }
    } else {
      this.showConfirm();
    }

  }

  startEMVTransaction(accountFeature, isManualPayment, userPaymentData: any) {
    this.appInsightService.trackTrace(new TraceTelemetry(`${accountFeature} - StartEMVTransaction - UserPaymentData`),
      { UserPaymentData: JSON.stringify(userPaymentData) }
    );

    const readerParam = new MTMagneFlexParameter.readerParameter();
    readerParam.timeLimit = this.cardReaderEmvConfigData.timeLimit;
    readerParam.amount = userPaymentData.Amount;
    readerParam.cashBack = this.cardReaderEmvConfigData.cashBack;
    readerParam.option = this.cardReaderEmvConfigData.option;
    readerParam.currencyCode = this.cardReaderEmvConfigData.currencyCode;
    readerParam.cardType =  this.supportedCardType;
    readerParam.transactionType =  this.cardReaderEmvConfigData.transactionType;
    readerParam.quickChipMode = this.cardReaderEmvConfigData.quickChipMode;
    readerParam.endSession = this.cardReaderEmvConfigData.endSession;

    const requestParam = new MTMagneFlexParameter.requestParameter();
    requestParam.operation = MagneFlexOp.REQUEST_SMART_CARD_TRANSACTION;
    requestParam.httpMethod = HTTPMethod.NONE;
    requestParam.fullScreenMode = false;
    requestParam.destinationURL = '';
    requestParam.closeDeviceAfter = false;

    this.paymentType = accountFeature;
    this.isManualPayment = isManualPayment;
    this.userPaymentData = userPaymentData;

    MTMagneFlexLib.requestSmartCard(
      readerParam,
      requestParam,
      this.emvCardarcqDataCallBack,
      this.emvCardbatchDataCallback
    );
  }
}
