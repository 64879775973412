import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PaymentConfirmation } from 'src/app/transaction/models/PaymentConfirmation';
import { UserAccount } from 'src/app/transaction/models/UserAccount';

@Injectable({
  providedIn: 'root'
})
export class EmitterService {
  userProfile: any;
  linkedAccounts: UserAccount[] = [];
  accountFeaturesChange = new BehaviorSubject<string>('');
  transactionConfirmation = new BehaviorSubject<PaymentConfirmation>(new PaymentConfirmation());
  ForgetUsernameConfirmation = new BehaviorSubject<string>(undefined);
  chapterChange = new BehaviorSubject<boolean>(false);
  accountChange = new BehaviorSubject<boolean>(false);
  errorPage = new BehaviorSubject<boolean>(false);
  cardEntryPage = new BehaviorSubject<boolean>(false);
  transactionFailed = new BehaviorSubject<boolean>(false);

  constructor() {
    this.accountFeaturesChange.asObservable();
    this.transactionConfirmation.asObservable();
    this.ForgetUsernameConfirmation.asObservable();
    this.errorPage.asObservable();
    this.chapterChange.asObservable();
    this.accountChange.asObservable();
    this.cardEntryPage.asObservable();
    this.transactionFailed.asObservable();
  }

  accountFeatureChanged(feature: string) {
    this.accountFeaturesChange.next(feature);
  }

  transactionConfirmationDone(result: PaymentConfirmation) {
    this.transactionConfirmation.next(result);
  }

  forgetUsernameConfirmationDone(username: string) {
    this.ForgetUsernameConfirmation.next(username);
  }

  errorPageChanged(isErrorPage: boolean) {
    this.errorPage.next(isErrorPage);
  }

  chapterChanged(isChapterChanged: boolean) {
    this.chapterChange.next(isChapterChanged);
  }

  accountChanged(isAccountChanged: boolean) {
    this.accountChange.next(isAccountChanged);
  }

  cardEntryPageOpened(isChanged: boolean) {
    this.cardEntryPage.next(isChanged);
  }

  triggerTransactionFailed() {
    this.transactionFailed.next(true);
  }
}
