import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaymentConfirmation } from '../../models/PaymentConfirmation';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { ApplicationInsightsService } from 'src/app/shared/services/appinsight.service';
import { EventTelemetry } from 'src/app/shared/models/application-insights/event.telemetry.model';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'transaction-confirmation',
  templateUrl: './transaction-confirmation.component.html',
  styleUrls: ['./transaction-confirmation.component.scss']
})
export class TransactionConfirmationComponent implements OnInit, OnDestroy {
  feature: string;
  paymentDetails: PaymentConfirmation;

  transactionConfirmation: Subscription;
  accountFeatureChanged: Subscription;

  constructor(
    private emitterService: EmitterService,
    private navigationService: NavigationService,
    private appInsightService: ApplicationInsightsService
  ) {}

  confirmationDone() {
    this.navigationService.navigateToDashboard();
  }

  ngOnInit() {
    this.appInsightService.trackPageView(new PageViewTelemetry('TransactionConfirmationPage'));

    this.accountFeatureChanged = this.emitterService.accountFeaturesChange.subscribe(
      feature => {
        this.feature = feature;
      }
    );

    this.transactionConfirmation = this.emitterService.transactionConfirmation.subscribe(
      (paymentDetails: PaymentConfirmation) => {
        if (paymentDetails.Success) {
          paymentDetails.Amount = (Number(paymentDetails.Amount) / 100);
          this.paymentDetails = paymentDetails;
          this.appInsightService.trackEvent(
            new EventTelemetry(`${this.feature} - TransactionConfirmation - ngOnInit - PaymentConfirmationData`),
            { PaymentConfirmationData: JSON.stringify(paymentDetails) });
        } else {
          this.navigationService.navigateToDashboard();
        }
      }
    );
  }

  ngOnDestroy() {
    this.transactionConfirmation.unsubscribe();
    this.accountFeatureChanged.unsubscribe();
  }
}
