export class ApiInsightsError {
  public Error: string;
  public ErrorDescription: string;
  public ErrorMethod: string;
  public Path: string;
  public Comment: string;
  public Category: string;

  constructor(error: string, errorDescription: string, errorMethod: string, path: string, comment: string) {
    this.Error = error;
    this.ErrorDescription = errorDescription;
    this.ErrorMethod = errorMethod;
    this.Path = path;
    this.Comment = comment;
    this.Category = 'Anywhere';
  }
}
