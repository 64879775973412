// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TransactionRoutingModule } from './transaction-routing.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {AutoCompleteModule} from 'primeng/autocomplete';

// Directives
import { NumberDirective } from '../directives/numbers-only.directive';

// Guards
import { AuthGuard } from '../shared/services/auth.guard';
import { RoleGuard } from '../shared/services/role.guard';

// Components
import { TransactionComponent } from './transaction.component';
import { AccountFeaturesComponent } from './components/account-features/account-features.component';
import { DonateComponent } from './components/donate/donate.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { CardEntryComponent } from './components/card-entry/card-entry.component';
import { MemberPaymentComponent } from './components/member-payment/member-payment.component';
import { GuestFormComponent } from './components/guest-form/guest-form.component';
import { MemberSearchComponent } from './components/member-search/member-search.component';
import { TransactionConfirmationComponent } from './components/transaction-confirmation/transaction-confirmation.component';

// Services
import { UtilService } from '../shared/services/util.service';
import { TransactionService } from './services/transaction.service';
import { PaymentService } from './services/payment.service';
import { MessageService } from 'primeng/api';
import { ApplicationInsightsService } from '../shared/services/appinsight.service';
import { from } from 'rxjs';

@NgModule({
  imports: [
    RouterModule,
    TransactionRoutingModule,
    CommonModule,
    FormsModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    InputMaskModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    RadioButtonModule,
    CheckboxModule,
    CardModule,
    ButtonModule,
    TableModule,
    ToastModule,
    ProgressSpinnerModule,
    AutoCompleteModule
  ],
  declarations: [
    TransactionComponent,
    AccountFeaturesComponent,
    DepositComponent,
    NumberDirective,
    DonateComponent,
    CardEntryComponent,
    MemberPaymentComponent,
    GuestFormComponent,
    MemberSearchComponent,
    TransactionConfirmationComponent
  ],
  exports: [
    TransactionComponent,
    NumberDirective
  ],
  providers: [
    UtilService,
    AuthGuard,
    TransactionService,
    PaymentService,
    MessageService,
    RoleGuard,
    ApplicationInsightsService
  ]
})
export class TransactionModule { }
