import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationInsightsModule } from 'angular-application-insights-v2';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';

import { FooterModule } from './shared/modules/footer/footer.module';
import { HeaderModule } from './shared/modules/header/header.module';
import { TransactionModule } from './transaction/transaction.module';
import { LoginModule } from './login/login.module';

import { AppComponent } from './app.component';
import { InternalServerErrorComponent } from './shared/component/internal-server-error/internal-server-error.component';
import { LoaderComponent } from './shared/component/loader/loader.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';

import { APIInterceptor } from './shared/services/api-interceptor';
import { ApiService } from './shared/services/api.service';
import { ApplicationInsightsService } from './shared/services/appinsight.service';
import { AuthService } from './shared/services/auth.service';
import { EmitterService } from './shared/services/emitter.service';
import { LoaderInterceptor } from './shared/services/loader.interceptor';
import { LoaderService } from './shared/services/loader.service';
import { UtilService } from './shared/services/util.service';
import { MessageService } from 'primeng/api';
import { NavigationService } from './shared/services/navigation.service';
import { ErrorHandleService } from './shared/services/error-handle.service';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    PageNotFoundComponent,
    InternalServerErrorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LoginModule,
    HeaderModule,
    TransactionModule,
    FormsModule,
    FooterModule,
    ToastModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsightsConfig.instrumentationKey
    })
  ],
  providers: [
    ApiService,
    AuthService,
    UtilService,
    MessageService,
    EmitterService,
    LoaderService,
    NavigationService,
    ErrorHandleService,
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ApplicationInsightsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(applicationInsightsService: ApplicationInsightsService) {
    applicationInsightsService.init();
  }
}
