import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[numbersOnly]'
})
export class NumberDirective {
  // tslint:disable-next-line: no-input-rename
  @Input('decimals') decimals = 2;
  specialKeys: any;

  private check(value: string, decimals: number) {
    if (decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      return String(value).match(new RegExp('^\\s*((\\d+(\\.\\d{0,' + decimals + '})?)|((\\d*(\\.\\d{1,' + decimals + '}))))\\s*$'));
    }
  }

  constructor(private el: ElementRef) {
    this.specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if ((this.el.nativeElement.value).concat(event.key) &&
      !this.check((this.el.nativeElement.value).concat(event.key), this.decimals)) {
      event.preventDefault();
    }
  }
}
