import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TransactionService } from '../../services/transaction.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Member } from '../../models/Member';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { Subscription } from 'rxjs';
import {
  ApplicationInsightsService
} from 'src/app/shared/services/appinsight.service';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';
import { emailRegexPattern, emailMaxLength } from 'src/app/shared/constants';

@Component({
  selector: 'member-search',
  templateUrl: './member-search.component.html',
  styleUrls: ['./member-search.component.scss']
})
export class MemberSearchComponent implements OnInit, OnDestroy {
  memberSearchForm: FormGroup;

  @Output() selectMember: EventEmitter<any> = new EventEmitter<any>();

  type = 'member';
  members: Member[];
  searchValue: string;

  submitted: boolean;
  searchResult: boolean;
  advancedSearch: boolean;
  searchAllChapter: boolean;
  isMemberSearchLoading: boolean;
  isMembersPresent: boolean;
  isSearchFocus = false;
  isNameFocus = false;
  isEmailFocus = false;
  isBhIDFocus = false;
  isExternalIDFocus = false;
  changeChapterSubscription: Subscription;

  constructor(
    private transactionService: TransactionService,
    private storageService: StorageService,
    private emitterService: EmitterService,
    private applicationInsightsService: ApplicationInsightsService,
    private errorService: ErrorHandleService
  ) {
    this.memberSearchForm = new FormGroup({
      MemberName: new FormControl('', [Validators.maxLength(20)]),
      EmailAddress: new FormControl('', [
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]),
      BillHighwayId: new FormControl('', [Validators.maxLength(20)]),
      ExternalId: new FormControl('', [Validators.maxLength(20)]),
      NationalId: new FormControl(
        this.storageService.getContext('NationalId'),
        Validators.required
      ),
      ChapterId: new FormControl(
        this.storageService.getContext('ChapId'),
        Validators.required
      ),
      RequestingUserId: new FormControl(
        this.storageService.getContext('UserId'),
        Validators.required
      )
    });
  }

  showAdvancedSearch() {
    this.advancedSearch = !this.advancedSearch;
    this.searchResult = false;
  }

  get form() {
    return this.memberSearchForm.controls;
  }

  showSearchResult() {
    this.submitted = true;
    if (this.memberSearchForm.valid) {
      this.searchMembers(this.memberSearchForm.value);
    }
  }

  onMemberClick(member) {
    this.selectMember.emit(member);
  }

  resetAdvancedSearch() {
    this.form.EmailAddress.setValue('');
    this.form.BillHighwayId.setValue('');
    this.form.ExternalId.setValue('');
  }

  search() {
    this.searchValue ? this.form.MemberName.setValue(this.searchValue) : this.form.MemberName.setValue('');
    this.searchMembers(this.memberSearchForm.value);
  }

  searchMembers(member: Member) {
    member.ChapterId = this.searchAllChapter ? 999 : this.storageService.getContext('ChapId');

    this.isMemberSearchLoading = true;
    this.transactionService.searchMembers(member).subscribe(
      (members: Member[]) => {
        this.members = members;
        this.isMembersPresent = this.members.length !== 0 ? true : false;
        this.searchResult = true;
        this.advancedSearch = false;
        this.isMemberSearchLoading = false;
      },
      error => {
        this.errorService.handleErrorResponse(error, 'MemberSearch', 'searchMembers');
        this.isMemberSearchLoading = false;
      }
    );
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('MemberSearchPage'));
    this.changeChapterSubscription = this.emitterService.chapterChange.subscribe(
      isChapterChanged => {
        if (isChapterChanged) {
          this.resetFormData();
        }
      }
    );
  }

  resetFormData() {
    this.memberSearchForm.reset();
    this.advancedSearch = false;
    this.searchResult = false;
    this.searchValue = '';
    this.members = [];

    this.form.NationalId.setValue(this.storageService.getContext('NationalId'));
    this.form.ChapterId.setValue(this.storageService.getContext('ChapId'));
    this.form.RequestingUserId.setValue(
      this.storageService.getContext('UserId')
    );
  }

  ngOnDestroy(): void {
    this.applicationInsightsService.flush();
    this.changeChapterSubscription.unsubscribe();
  }
}
