import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { MessageService } from 'primeng/api';
import { getErrorMessage, MessageType, CaptachErrors, NonAccessibleRoles, scrollToTop, scrollToBottom } from '../shared/constants';
import { StorageService } from '../shared/services/storage.service';
import { UserProfile } from '../transaction/models/UserProfile';
import { ApplicationInsightsService, SeverityLevel } from '../shared/services/appinsight.service';
import { AvailableChapter } from '../transaction/models/AvailableChapter';
import { UserAccount } from '../transaction/models/UserAccount';
import { NavigationService } from '../shared/services/navigation.service';
import { EventTelemetry } from '../shared/models/application-insights/event.telemetry.model';
import { UtilService } from '../shared/services/util.service';
import { ErrorHandleService } from '../shared/services/error-handle.service';
import { NavAccessItem } from '../transaction/models/NavAccessItem';
import { PageViewTelemetry } from '../shared/models/application-insights/pageview.telemetry.model';
declare const grecaptcha: any;

@Component({
  selector: 'app-home',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoginPage: boolean;
  loginForm: FormGroup;
  isLoggedIn: boolean;
  hasAnywhereAccess: boolean;
  displayRedirectModal: boolean;
  redirectLink = '';

  submitted = false;
  isMultipleAccounts: boolean;
  loginProfile: UserProfile;
  linkedAccounts: UserAccount[];
  filteredAccounts: UserAccount[] = [];
  searchValue: string;
  isClearIcon = false;
  captchaKey:string;
  isChangeAccountLinkShown = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private applicationInsightsService: ApplicationInsightsService,
    private errorService: ErrorHandleService,
    private utilService: UtilService
  ) {
    this.captchaKey = this.storageService.getConfigValue('googleRecaptchaKey');
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('LoginPage'));

    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.maxLength(50)]],
      password: ['', [Validators.required, Validators.maxLength(50)]],
      userId: ['']
    });

    if (this.storageService.isLoggedIn()) {
      this.applicationInsightsService.flush();
      this.navigationService.navigateToDashboard();
    }
  }

  get form() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const component = this;
    grecaptcha.ready(() => {
      grecaptcha.execute(this.captchaKey, { action: 'login' }).then(
        recaptchaResponse => {
          component.loginService.verifyCaptcha(recaptchaResponse).subscribe(serverResponse => {
            component.applicationInsightsService.trackEvent(
              new EventTelemetry('Login - onSubmit - Google reCaptcha'),
              { ServerResponse: serverResponse }
            );
            if (serverResponse.success) {
              component.loginUser();
            } else {
              // More details in error-code property
              const errorInfo = this.utilService.prepareAppInsightError(
                CaptachErrors[serverResponse['error-codes'][0]],
                'GoogleReCaptchaSiteVerify',
                'Login - onSubmit - Google reCaptcha site verify'
              );
              component.errorService.AppInsightLogError(errorInfo).subscribe(
                () => { },
                err => {
                  component.applicationInsightsService.trackException(
                    CaptachErrors[serverResponse['error-codes'][0]],
                    SeverityLevel.Critical
                  );
                }
              );
              component.messageService.add(getErrorMessage(MessageType.Login_Failed, 'loginError'));
            }
          });
        },
        error => {
          component.applicationInsightsService.trackException(error, SeverityLevel.Error);
          this.errorService.handleErrorResponse(error, 'Login', 'onSubmit');
        }
      );
    });
  }

  loginUser() {
    this.loginService
      .Login(this.form.userName.value, this.form.password.value, this.form.userId.value)
      .subscribe(
        ({ UserProfile, SecurityToken, AvailableChapters }) => {
          this.applicationInsightsService.setAuthenticatedUserContext(
            UserProfile.UserId,
            UserProfile.EmailAddress,
            true
          );

          this.applicationInsightsService.flush();
          this.loginProfile = UserProfile;

          if (this.checkHasAnywhereAccess(this.loginProfile.NavAccess)) {
            if (!NonAccessibleRoles.includes((this.loginProfile.UserRole).toLowerCase())) {
              const availableChapters = this.filterAvailableChapters(AvailableChapters);
              if (availableChapters.length !== 0) {
                this.applicationInsightsService.trackEvent(new EventTelemetry('UserLoginSuccess'),
                { EmailAddress : this.loginProfile.EmailAddress });
                this.loginProfile.SecurityToken = SecurityToken;
                this.loginProfile.AvailableChapters = availableChapters;
                this.loginProfile.showChangeAccountLink = this.isChangeAccountLinkShown;
                this.storageService.setProfileContext(this.loginProfile);
                this.navigationService.navigateToDashboard();
              } else {
                this.messageService.add(getErrorMessage(MessageType.No_Chapter_Found, 'loginError'));
              }
          } else {
            this.messageService.add(getErrorMessage(MessageType.UserRole_Not_Allowed, 'loginError'));
          }
        } else {
          this.messageService.add(getErrorMessage(MessageType.Anywhere_Payment_Not_Enabled, 'loginError'));
        }}, loginError => {
          const errorData = loginError.error;
          const errorInfo = this.utilService.prepareAppInsightError(loginError, 'Login', 'Login - onSubmit', {
            url: loginError.url,
            status: loginError.status
          });

          this.errorService.AppInsightLogError(errorInfo).subscribe(
            () => { },
            err => {
              this.applicationInsightsService.trackException(loginError, SeverityLevel.Critical);
            }
          );

        if (errorData && errorData.hasOwnProperty('Messages')) {
          if (errorData.Messages[0].Code === '400.20113') {
            this.validateUserLinkedAccount();
          } else {
            this.errorService.createToastMessages(errorData);
          }
        }
      });
    this.form.userId.setValue('')
  }

  getUser(account) {
    this.form.userId.setValue(account.UserId);
    this.isChangeAccountLinkShown = true;
    scrollToTop();
    this.isMultipleAccounts = false;
    this.loginUser();
  }

  searchAccounts() {
    this.filteredAccounts = this.linkedAccounts.filter(account => {
      return (
        (this.searchValue ? this.searchValue.trim() : '') === '' ||
        account.DisplayName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        ) ||
        account.ChapterName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        )
      );
    });
  }

  resetUser() {
    this.form.userId.setValue('')
    this.submitted = false;
    this.isMultipleAccounts = false;
    scrollToTop();
    this.filteredAccounts = this.linkedAccounts;
  }

  validateUserLinkedAccount() {
    this.loginService.getUserLinkedAccounts(this.form.userName.value).subscribe(
      accounts => {
        this.linkedAccounts = accounts.filter(account => {
          return !NonAccessibleRoles.includes(account.UserRole.toLowerCase())
        })
        this.filteredAccounts = this.linkedAccounts;
        if (this.linkedAccounts.length !== 0) {
          if (this.linkedAccounts.length === 1) {
            this.isChangeAccountLinkShown = false;
            this.form.userId.setValue(this.linkedAccounts[0].UserId)
            this.loginUser();
          } else {
            this.isChangeAccountLinkShown = true;
            this.isMultipleAccounts = (this.linkedAccounts.length !== 1);
            scrollToBottom();
            this.submitted = false;
          }
        } else {
          this.messageService.add(getErrorMessage(MessageType.UserRole_Not_Allowed, 'loginError'));
        }
      },
      errorResponse => {
        scrollToTop();
        this.isMultipleAccounts = false;
        this.submitted = false;
        this.messageService.add(getErrorMessage(MessageType.Login_Failed, 'loginError'));
      }
    );
  }

  filterAvailableChapters(availableChapters: AvailableChapter[]) {
    return availableChapters.filter(chapter => {
      return (chapter.NavAccess.filter(navAccess => {
        return (navAccess.Name === 'HasAnywhereAccess' && navAccess.Value);
      }).length > 0
      );
    });
  }

  checkHasAnywhereAccess(navAccess: NavAccessItem[]) {
    navAccess.forEach((navItem: NavAccessItem) => {
      if (navItem.Name === 'HasAnywhereAccess' && navItem.Value) {
        this.hasAnywhereAccess = true;
      }
    });
    return this.hasAnywhereAccess;
  }

  externalRedirect(event, link) {
    if (link) {
      event.preventDefault();
      this.displayRedirectModal = true;
      this.redirectLink = link;
    } else {
      window.location.href = link;
    }
  }

  redirectAction(shouldProceed: boolean) {
    if (shouldProceed) {
      window.location.href = this.redirectLink;
    } else {
      this.displayRedirectModal = false;
    }
  }

  navigate(page) {
    this.applicationInsightsService.flush();
    this.navigationService.navigateTo(`/login/${page}`);
  }

  ngOnDestroy() {
    this.applicationInsightsService.flush();
  }
}
