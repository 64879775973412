import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header.component';
import { HeaderRoutingModule } from './header-routing.module';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ListboxModule } from 'primeng/listbox';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [RouterModule, HeaderRoutingModule, CommonModule, MenuModule, SidebarModule, ListboxModule, DialogModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
  providers: [MessageService]
})
export class HeaderModule { }
