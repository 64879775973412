import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { PaymentType, emailRegexPattern, emailMaxLength } from '../../../shared/constants';
import { TransactionService } from '../../services/transaction.service';
import { Subscription } from 'rxjs';
import { ApplicationInsightsService } from 'src/app/shared/services/appinsight.service';

import { StorageService } from 'src/app/shared/services/storage.service';
import { Invoice } from '../../models/Invoice';
import { Member } from '../../models/Member';
import { CurrencyPipe } from '@angular/common';
import { PaymentService } from '../../services/payment.service';
import { UserPaymentData } from '../../models/PaymentInfo';
import { MemberBalance } from '../../models/MemberBalance';
import { DonorInfo } from '../../models/donorInfo';
import { MemberInvoice } from '../../models/MemberInvoice';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { TraceTelemetry } from 'src/app/shared/models/application-insights/trace.telemetry.model';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'feature-member-payment',
  templateUrl: './member-payment.component.html',
  styleUrls: ['./member-payment.component.scss']
})
export class MemberPaymentComponent implements OnInit, OnDestroy {
  memberPaymentTypes: any = [];
  selectedMember: Member;

  invoices: Invoice[] = [];
  specificInvoices: Invoice[] = [];
  memberInvoice: MemberInvoice[] = [];
  selectedInvoices: Invoice[] = [];
  donorInfo: DonorInfo;
  memberBalances: MemberBalance;

  otherAmount: number;
  submitted: boolean;
  inputBarClosed = true;
  isMemberSearchMode = true;
  isMemberPaymentMode: boolean;
  isManualPayment: boolean;
  isAmountIcon = false;
  isNotes = false;
  inputEmail: string;
  memberPaymentForm: FormGroup;
  isGetChargesLoading: boolean;
  isEMVTransactionEnabled = false;

  cardSwipeData: string;
  selectedPaymentType: string;
  changeChapterSubscription: Subscription;

  constructor(
    private emitterService: EmitterService,
    private transactionService: TransactionService,
    private storageService: StorageService,
    private applicationInsightsService: ApplicationInsightsService,
    private paymentService: PaymentService,
    private errorService: ErrorHandleService
  ) {
    this.memberPaymentForm = new FormGroup({
      paymentType: new FormControl('', Validators.required),
      paymentAmount: new FormControl('', [
        Validators.required,
        Validators.max(999999.99),
        Validators.min(0.01),
        Validators.pattern(/^\d{0,}(\.\d{1,2})?$/)
      ]),
      shouldSendEmailReceipt: new FormControl(false),
      receiptEmail: new FormControl('', [
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]),
      notes: new FormControl(''),
      billhighwayId: new FormControl('')
    });
    this.isEMVTransactionEnabled = this.storageService.getConfigValue('isEMVTransactionEnabled');
  }

  get form() {
    return this.memberPaymentForm.controls;
  }

  getMemberBalances(selectedMember: Member) {
    this.isGetChargesLoading = true;
    this.memberPaymentTypes = [];

    this.transactionService
      .getMemberBalances(this.getContextData('UserId'), selectedMember.BillhighwayAccountNumber)
      .subscribe(
        (memberBalance: MemberBalance) => {
          this.memberBalances = memberBalance;
          if (memberBalance.PastDueAmount && memberBalance.PastDueAmount > 0) {
            const pastDue = {
              type: 'PastDueAmount',
              amt: memberBalance.PastDueAmount,
              paymentTypeLabel:
                'Only my past due amount - ' +
                new CurrencyPipe('en-US').transform(memberBalance.PastDueAmount)
            };
            this.memberPaymentTypes.push(pastDue);
          }
          if (memberBalance.TotalAccountBalance && memberBalance.TotalAccountBalance > 0) {
            const totalBalance = {
              type: 'TotalAccountBalance',
              amt: memberBalance.TotalAccountBalance,
              paymentTypeLabel:
                'My account balance in full - ' +
                new CurrencyPipe('en-US').transform(
                  memberBalance.TotalAccountBalance
                )
            };
            this.memberPaymentTypes.push(totalBalance);
          }
          if (memberBalance.CurrentAmountDue && memberBalance.CurrentAmountDue > 0) {
            const currentDue = {
              type: 'CurrentAmountDue',
              amt: memberBalance.CurrentAmountDue,
              paymentTypeLabel:
                'Pay my current amount due - ' +
                new CurrencyPipe('en-US').transform(
                  memberBalance.CurrentAmountDue
                )
            };
            this.memberPaymentTypes.push(currentDue);
          }

          this.getSpecificCharges(selectedMember);
        },
        error => {
          this.errorService.handleErrorResponse(
            error,
            'MemberPayment',
            'getMemberBalances'
          );
          this.isGetChargesLoading = false;
        }
      );
  }

  onActivate(componentReference) {
    if (componentReference.type === 'card-entry') {
      this.isManualPayment = true;
      componentReference.userPaymentData = this.memberPaymentForm.valid
        ? this.getUserPaymentData()
        : false;
      this.emitterService.cardEntryPageOpened(true);
    }
  }

  onDeactivate() {
    this.emitterService.cardEntryPageOpened(false);
    if (!this.memberPaymentForm.valid) {
      this.isMemberSearchMode = true;
    } else {
      this.isManualPayment = false;
    }
  }

  onPaymentTypeChange(paymentType) {
    const selectedMember: Member = this.selectedMember;

    this.selectedInvoices = [];
    !isNaN(paymentType.value.amt)
      ? this.form.paymentAmount.setValue(paymentType.value.amt)
      : this.form.paymentAmount.setValue('');
    this.selectedPaymentType = paymentType.value.type;

    switch (this.selectedPaymentType) {
      case 'CurrentAmountDue':
        if (this.memberBalances.AvailableCredit === 0) {
          this.isGetChargesLoading = true;
          this.transactionService
            .getMemberPaymentInvoices(this.storageService.getContext('UserId'), selectedMember.BillhighwayAccountNumber, 'Current')
            .subscribe(
              ({ Invoices }) => {
                this.invoices = this.suppressNegativeInvoices(Invoices);
                this.invoices.forEach((currentAmountInvoice, index) => {
                  this.invoices[index].Selected = true;
                });
                this.isGetChargesLoading = false;
              },
              error => {
                this.isGetChargesLoading = false;
                this.errorService.handleErrorResponse(
                  error,
                  'MemberPayment',
                  'getMemberPaymentInvoices (CurrentAmountDue)'
                );
              }
            );
        }
        break;
      case 'PastDueAmount':
        if (this.memberBalances.AvailableCredit === 0) {
          this.isGetChargesLoading = true;
          this.transactionService
            .getMemberPaymentInvoices(this.storageService.getContext('UserId'), selectedMember.BillhighwayAccountNumber, 'PastDue')
            .subscribe(
              ({ Invoices }) => {
                this.invoices = this.suppressNegativeInvoices(Invoices);
                this.invoices.forEach((pastDueAmountInvoice, index) => {
                  this.invoices[index].Selected = true;
                });
                this.isGetChargesLoading = false;
              },
              error => {
                this.errorService.handleErrorResponse(error, 'MemberPayment', 'getMemberPaymentInvoices (PastDueAmount)');
                this.isGetChargesLoading = false;
              }
            );
        }
        break;
      case 'TotalAccountBalance':
        if (this.memberBalances.AvailableCredit === 0) {
          this.isGetChargesLoading = true;
          this.transactionService
            .getMemberPaymentInvoices(this.storageService.getContext('UserId'), selectedMember.BillhighwayAccountNumber, 'Full')
            .subscribe(
              ({ Invoices }) => {
                this.invoices = this.suppressNegativeInvoices(Invoices);
                this.invoices.forEach((invoice, index) => {
                  this.invoices[index].Selected = true;
                });
                this.isGetChargesLoading = false;
              },
              error => {
                this.errorService.handleErrorResponse(error, 'MemberPayment', 'getMemberPaymentInvoices (TotalAccountBalance)');
                this.isGetChargesLoading = false;
              }
            );
        }
        break;
      case 'SpecificCharge':
        this.invoices = this.specificInvoices;
        this.invoices.forEach((invoice, index) => {
          this.invoices[index].Selected = false;
        });
        this.isGetChargesLoading = false;
        break;
      case 'OtherAmount':
        this.submitted = false;
        this.isGetChargesLoading = false;
        this.form.paymentAmount.reset();
        break;
    }
  }

  suppressNegativeInvoices(invoices) {
    return invoices.filter((invoice) => invoice.Balance > 0);
  };

  handleReceiptEmail() {
    if (!this.memberPaymentForm.get('shouldSendEmailReceipt').value) {
      this.form.receiptEmail.setValue('');
    } else {
      this.inputBarClosed = false;
      this.form.receiptEmail.setValue(this.inputEmail);
    }
  }

  chargePayment() {
    this.submitted = true;
    if (this.memberPaymentForm.get('shouldSendEmailReceipt').value) {
      this.memberPaymentForm
        .get('receiptEmail')
        .setValidators([
          Validators.pattern(emailRegexPattern),
          Validators.required,
          Validators.maxLength(emailMaxLength)
        ]);
      this.memberPaymentForm.get('receiptEmail').updateValueAndValidity();
    } else {
      this.memberPaymentForm
        .get('receiptEmail')
        .setValidators([
          Validators.pattern(emailRegexPattern),
          Validators.maxLength(emailMaxLength)
        ]);
      this.memberPaymentForm.get('receiptEmail').updateValueAndValidity();
    }

    if (this.memberPaymentForm.valid && this.paymentService.validateBrowser()) {
      if (this.isEMVTransactionEnabled) {
        this.applicationInsightsService.trackTrace(
          new TraceTelemetry('Member Payment - ChargePayment - IsEmvEnabled'),
          { IsEmvEnabled: JSON.stringify(this.isEMVTransactionEnabled) });
          this.paymentService.startEMVTransaction(PaymentType.PersonPayment, false, this.getUserPaymentData());
      } else {
        this.paymentService.startTransaction(
          PaymentType.PersonPayment,
          false,
          this.getUserPaymentData()
        );
      }
    }
  }

  selectInvoice(invoice) {
    if (invoice.Selected) {
      this.selectedInvoices.push(invoice);
    } else {
      this.selectedInvoices.splice(this.selectedInvoices.indexOf(invoice), 1);
    }

    const totalInvoiceAmount = Array.from(new Set(this.selectedInvoices)).map(
      inv => {
        return Number(inv.Balance);
      }
    );

    if (totalInvoiceAmount.length > 0) {
      this.form.paymentAmount.setValue(
        totalInvoiceAmount.reduce((a, b) => a + b)
      );
    } else {
      this.form.paymentAmount.setValue(0);
    }
  }

  getSpecificCharges(selectedMember: Member) {
    this.transactionService.getMemberPaymentInvoices(
      this.storageService.getContext('UserId'), selectedMember.BillhighwayAccountNumber, 'Specific').subscribe(
        ({ Invoices }) => {
          this.invoices = Invoices;
          this.specificInvoices = Invoices;
          this.invoices.forEach((invoice, index) => {
            this.invoices[index].Selected = false;
          });
          if (this.invoices.length > 0) {
            const specificCharge = {
              type: 'SpecificCharge',
              amt: undefined,
              paymentTypeLabel: 'Select specific charges'
            };
            this.memberPaymentTypes.push(specificCharge);
          }

          this.memberPaymentTypes.push({
            type: 'OtherAmount',
            amt: undefined,
            paymentTypeLabel: 'Other amount'
          });

          this.memberPaymentTypes = [...this.memberPaymentTypes];

          this.isGetChargesLoading = false;
        },
        error => {
          this.errorService.handleErrorResponse(error, 'MemberPayment', 'getMemberPaymentInvoices (SpecificCharge)');
          this.isGetChargesLoading = false;
        }
      );
  }

  getUserPaymentData() {
    let userPaymentData = new UserPaymentData();
    userPaymentData.UserId = this.selectedMember.BillhighwayAccountNumber;
    userPaymentData.Memo = this.form.notes.value;
    userPaymentData.Amount = Number((this.form.paymentAmount.value * 100).toFixed());

    if (this.form.shouldSendEmailReceipt.value) {
      userPaymentData.ReceiptEmail = this.form.receiptEmail.value;
    }

    if (this.donorInfo) {
      userPaymentData = { ...userPaymentData, ...{ DonorInfo: this.donorInfo } };
    }

    if (
      this.selectedPaymentType === 'CurrentAmountDue' ||
      this.selectedPaymentType === 'PastDueAmount' ||
      this.selectedPaymentType === 'TotalAccountBalance'
    ) {
      this.selectedInvoices = this.invoices;
    }

    if (this.selectedInvoices.length > 0) {
      this.selectedInvoices.forEach(invoice => {
        if (invoice.Selected) {
          this.memberInvoice.push(
            new MemberInvoice(invoice.InvoiceNo, Number(invoice.Balance))
          );
        }
      });

      if (this.memberInvoice.length > 0) {
        this.memberInvoice.forEach(memberInvoice => {
          memberInvoice.Amount = Number((memberInvoice.Amount * 100).toFixed());
        });
        userPaymentData = {
          ...userPaymentData,
          ...{ Invoices: this.memberInvoice }
        };
      }
    }

    this.applicationInsightsService.trackTrace(
      new TraceTelemetry('Member Payment - GetUserPaymentData - Payment Data'),
      { UserPaymentData: JSON.stringify(userPaymentData) }
    );

    return userPaymentData;
  }

  getContextData(key: string) {
    return this.storageService.getContext(key);
  }

  selectMember(member: Member) {
    this.selectedMember = member;

    this.submitted = false;
    this.memberPaymentForm.reset();
    this.donorInfo = new DonorInfo();

    this.donorInfo.Name = member.MemberName;
    this.donorInfo.Email = member.EmailAddress;

    this.isMemberSearchMode = false;
    this.isMemberPaymentMode = true;
    this.form.receiptEmail.setValue(member.EmailAddress);
    this.inputEmail = this.form.receiptEmail.value;
    this.form.shouldSendEmailReceipt.setValue(true);
    if (this.form.receiptEmail.value) {
      this.inputBarClosed = true;
    }
    this.getMemberBalances(member);
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('MemberPaymentPage'));

    this.changeChapterSubscription = this.emitterService.chapterChange.subscribe(
      isChapterChanged => {
        if (isChapterChanged) {
          this.memberPaymentForm.reset();
          this.isMemberPaymentMode = false;
          this.isMemberSearchMode = true;
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.changeChapterSubscription.unsubscribe();
  }
}
