import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { APIRequest } from './../models/api.request.model';
import { NavigationService } from './navigation.service';
import { StorageService } from './storage.service';
import { PaymentType } from '../constants';

export enum APIMethod {
  GET,
  POST,
  PUT,
  DELETE
}

export enum DownloadType {
  PDF,
  EXCEL,
  ZIP
}

@Injectable()
export class ApiService {
  result: string;
  baseURl: string;
  headers: HttpHeaders;

  constructor(public apiExecuter: HttpClient, private navigationService: NavigationService, private storageService: StorageService) {  }

  executeAPI(apiRequest: APIRequest) {
    this.baseURl = apiRequest.baseUrl ? apiRequest.baseUrl : environment.API;
    const nationalId = this.getContextData('NationalId');

    switch (apiRequest.method) {
      case APIMethod.GET:
        return this.apiExecuter
          .get(
            this.baseURl + apiRequest.endpoint,
            this.prepareHeader(nationalId)
          )
          .pipe(
            map(res => this.handleResponse(res)),
            catchError(data => {
              return throwError(data);
            })
          );

      case APIMethod.POST:
        return this.apiExecuter
          .post(
            this.baseURl + apiRequest.endpoint,
            apiRequest.getPayload(),
            this.prepareHeader(nationalId, apiRequest.paymentType)
          )
          .pipe(
            map(
              res => this.handleResponse(res),
              catchError(data => {
                return throwError(data);
              })
            )
          );

      case APIMethod.PUT:
        return this.apiExecuter
          .put(this.baseURl + apiRequest.endpoint, apiRequest.getPayload(), {
            headers: this.headers
          })
          .pipe(
            map(res => this.handleResponse(res)),
            catchError(data => {
              return throwError(data);
            })
          );

      case APIMethod.DELETE:
        return this.apiExecuter
          .delete(this.baseURl + apiRequest.endpoint, { headers: this.headers })
          .pipe(
            map(res => this.handleResponse(res)),
            catchError(data => {
              return throwError(data);
            })
          );
    }
  }

  getContextData(key: string) {
    return this.storageService.getContext(key);
  }

  handleResponse(res) {
    if (res.status === 500) {
      this.navigationService.internalServerError();
      return;
    }
    return res.body;
  }

  prepareHeader(userDataKey: string, paymentType?: string): object {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');

    if (userDataKey) {
      this.headers = this.headers.set('NationalId', userDataKey.toString());
    }

    switch (paymentType) {
      case PaymentType.ChargeToken:
        this.headers = this.headers.set('Authorization', this.storageService.getConfigValue('chargePaymentAuthValue'));
        this.headers = this.headers.delete('NationalId');
        break;
    }

    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');

    return {
      headers: this.headers,
      observe: 'response' as any
    };
  }
}
