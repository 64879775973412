import { Injectable, NgZone } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { ErrorHandleService } from './error-handle.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  ipAddress: any;
  authReq: any;
  constructor(
    private ngZone: NgZone,
    private authService: AuthService,
    private storageService: StorageService,
    private errorService: ErrorHandleService
  ) {  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authReq = req.clone({});

    if (req.url !== this.storageService.getConfigValue('paymentChargeApi')) {
      this.authReq = req.clone({
        setHeaders: {
          Authorization: `${this.storageService.getAccessToken()}`
        }
      });
    }
    return next.handle(this.authReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.log(event);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.logAppInsightError(err);
              if (err.error) {
                const error = err.error;
                if (error.hasOwnProperty('statusCode')) {
                  this.authService.receivedUnauthorizedErrorMessage(error.message);
                } else if (error.hasOwnProperty('Messages')) {
                  error.Messages.forEach(errorMessage => {
                    this.authService.receivedUnauthorizedErrorMessage(errorMessage.Message);
                  });
                } else {
                  this.authService.unauthorizedMessage();
                }
              } else {
                this.authService.unauthorizedMessage();
              }
            }
            if (err.status === 500) {
              this.logAppInsightError(err);
              this.ngZone.run(() => this.authService.redirectToServerError());
            }
            if (err.status === 404) {
              this.ngZone.run(() => this.authService.redirectToPageNotFoundError());
            }
          }
        }
      )
    );
  }

  logAppInsightError(error) {
    const errorMessage = this.errorService.getAppInsightErrorObject(error, 'Interceptor', error.status);
    this.errorService.AppInsightLogError(errorMessage);
  }
}
