import { DonorInfo } from './donorInfo';

export class DonatePayment {
  EncryptedPaymentData: string;
  IntegrationPartnerId: number;
  CreateAsDeposit: string;
  CampaignId: number;
  TokenId: string;
  DonotInfo: DonorInfo;
}
