import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import {
  getErrorMessage,
  MessageType,
  NonAccessibleRoles,
  scrollToTop,
  scrollToBottom,
  emailRegexPattern,
  emailMaxLength
} from 'src/app/shared/constants';
import { LoginService } from '../login.service';
import { UserAccount } from 'src/app/transaction/models/UserAccount';
import {
  ApplicationInsightsService,
  SeverityLevel
} from 'src/app/shared/services/appinsight.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';
import { TraceTelemetry } from 'src/app/shared/models/application-insights/trace.telemetry.model';

@Component({
  selector: 'app-home',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  forgotPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  submitted = false;
  isHintClicked = false;
  isActivationCodeValidated = false;
  isUserNameInPassword = false;
  isPasswordMatched: boolean;
  linkedAccounts: UserAccount[];
  filteredAccounts: UserAccount[] = [];
  searchValue: string;
  isClearIcon = false;
  selectedAccount: object;
  selectedUserId: number;
  isMultipleAccounts: boolean;
  requestCode = 'requestCode';
  submitCode = 'submitCode';
  isEmailFocus = false;
  isCodeFocus = false;
  isPasswordFocus = false;
  isConfirmPasswordFocus = false;

  constructor(
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private loginService: LoginService,
    private messageService: MessageService,
    private applicationInsightsService: ApplicationInsightsService,
    private errorService: ErrorHandleService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.applicationInsightsService.trackPageView(
      new PageViewTelemetry('ForgotPasswordPage')
    );

    this.forgotPasswordForm = this.formBuilder.group({
      emailAddress: [
        '',
        [
          Validators.required,
          Validators.pattern(emailRegexPattern),
          Validators.maxLength(emailMaxLength)
        ]
      ],
      codeSelection: ['requestCode', Validators.required],
      activationCode: ['0', Validators.required],
      userId: ['']
    });

    this.resetPasswordForm = this.formBuilder.group({
      newPassword: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?!.*(.)\1\1)(?=.{8,})/
          ),
          Validators.maxLength(128),
          Validators.minLength(8)
        ]
      ],
      confirmPassword: ['', Validators.required]
    });
  }

  get form() {
    return this.forgotPasswordForm.controls;
  }

  get resetForm() {
    return this.resetPasswordForm.controls;
  }

  checkForUsernameInPassword(password) {
    const name = this.forgotPasswordForm.get('userId').value.DisplayName;
    if (name) {
      if (password.search(name) === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      this.applicationInsightsService.trackTrace(
        new TraceTelemetry('Reset Password - Display Name Not Found.')
      );
      return false;
    }
  }

  checkPassword() {
    this.isPasswordMatched =
      this.resetForm.newPassword.value === this.resetForm.confirmPassword.value;
  }

  searchAccounts() {
    this.filteredAccounts = this.linkedAccounts.filter(account => {
      return (
        (this.searchValue ? this.searchValue.trim() : '') === '' ||
        account.DisplayName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        ) ||
        account.ChapterName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        )
      );
    });
  }

  resetFilters() {
    scrollToTop();
    this.filteredAccounts = this.linkedAccounts;
  }

  getLoginLinkedAccounts() {
    this.loginService
      .GetAllUserIds(this.forgotPasswordForm.get('emailAddress').value)
      .subscribe(
        accounts => {
          this.linkedAccounts = accounts.filter(account => {
            return !NonAccessibleRoles.includes(account.UserRole.toLowerCase());
          });
          this.filteredAccounts = this.linkedAccounts;
          if (this.linkedAccounts.length === 1) {
            this.form.userId.setValue(this.linkedAccounts[0]);
            this.selectedAccount = this.linkedAccounts[0];
            this.forgotPassword();
          } else if (this.linkedAccounts.length > 1) {
            this.isMultipleAccounts = true;
            scrollToBottom();
            this.submitted = false;
          } else {
            this.messageService.add(
              getErrorMessage(MessageType.UserRole_Not_Allowed, 'commonError')
            );
          }
        },
        error => {
          this.errorService.handleErrorResponse(
            error,
            'ForgotPassword',
            'onSubmit - GetAllUserIds'
          );
        }
      );
  }

  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    if (this.form.codeSelection.value === 'requestCode') {

      this.forgotPassword();
      this.submitted = false;
    } else {
      // submitCode case
      if (!this.selectedAccount || this.selectedAccount === {}) {
        this.getLoginLinkedAccounts();
      } else {
        this.verifyActivationCode();
      }
    }
  }

  forgotPassword() {
    const component = this;

    this.loginService
      .ForgetPasswordRequestCode(
        this.forgotPasswordForm.get('emailAddress').value,
        this.forgotPasswordForm.get('userId').value
      )
      .subscribe(
        () => {
          scrollToTop();
          this.isMultipleAccounts = false;
          if (this.forgotPasswordForm.get('userId').value === '') {
            this.getLoginLinkedAccounts();
          } else {
            this.messageService.add(
              getErrorMessage(MessageType.OTP_Sent, 'otpSent')
            );
          }
          this.form.activationCode.setValue('');
          this.form.codeSelection.setValue('submitCode');
        },
        (passwordError) => {
          const errorData = passwordError.error;
          const errorInfo = this.utilService.prepareAppInsightError(
            passwordError,
            'ForgotPassword',
            'ForgotPassword - onSubmit - ForgetPasswordRequestCode',
            { url: passwordError.url, status: passwordError.status }
          );
          component.errorService.AppInsightLogError(errorInfo).subscribe(
            () => {},
            (err) => {
              component.applicationInsightsService.trackException(
                passwordError,
                SeverityLevel.Error
              );
            }
          );
          if (errorData && errorData.hasOwnProperty('Messages')) {
            if (errorData.Messages[0].Code === '400.20107') {
              this.getLoginLinkedAccounts();
            } else {
              this.errorService.handleErrorResponse(passwordError);
            }
          } else {
            this.messageService.add(
              getErrorMessage(MessageType.Search_Username_Error, 'commonError')
            );
          }
        }
      );
  }

  verifyActivationCode() {
    this.isMultipleAccounts = false;
    this.loginService
      .ForgetPasswordValidateCode(
        this.forgotPasswordForm.get('emailAddress').value,
        this.forgotPasswordForm.get('activationCode').value
      )
      .subscribe(
        () => {
          this.isActivationCodeValidated = true;
        },
        codeValidationError => {
          const errorData = codeValidationError.error;
          this.errorService.handleErrorResponse(
            codeValidationError,
            'ForgotPassword',
            'onSubmit  GetAllUserIds'
          );
          if (errorData && errorData.hasOwnProperty('Messages')) {
            if (errorData.Messages[0].Code === '400.20122') {
              this.form.userId.setValue('');
              this.form.activationCode.setValue('0');
              this.form.codeSelection.setValue('requestCode');
            }
          }
        }
      );
    this.submitted = false;
  }

  onSubmitNewPassword() {
    this.submitted = true;
    this.isUserNameInPassword = false;

    this.checkPassword();
    if (!this.isPasswordMatched || this.resetPasswordForm.invalid) {
      return;
    }
    if (
      this.checkForUsernameInPassword(
        this.resetPasswordForm.get('newPassword').value
      )
    ) {
      this.isUserNameInPassword = true;
      return;
    }

    this.loginService
      .ResetPassword(
        this.forgotPasswordForm.get('emailAddress').value,
        this.forgotPasswordForm.get('activationCode').value,
        this.forgotPasswordForm.get('userId').value.UserId,
        this.resetPasswordForm.get('newPassword').value
      )
      .subscribe(
        () => {
          this.messageService.add(
            getErrorMessage(
              MessageType.Reset_Password_Success,
              'resetPasswordSuccess'
            )
          );
        },
        error => {
          const errorData = error.error;
          if (errorData && errorData.hasOwnProperty('Messages')) {
            this.errorService.handleErrorResponse(error, 'Forgot Password', 'onSubmitNewPassword')
          } else {
            this.messageService.add(getErrorMessage(MessageType.Reset_Password_No_UserID, 'commonError'))
          }
        }
      );
  }

  navogateToLogin() {
    this.applicationInsightsService.flush();
    this.navigationService.navigateToLogin();
  }

  ngOnDestroy() {
    this.applicationInsightsService.flush();
  }
}
