import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { RoleGuard } from '../shared/services/role.guard';

import { TransactionComponent } from './transaction.component';
import { AccountFeaturesComponent } from './components/account-features/account-features.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { DonateComponent } from './components/donate/donate.component';
import { MemberPaymentComponent } from './components/member-payment/member-payment.component';
import { CardEntryComponent } from './components/card-entry/card-entry.component';
import { GuestFormComponent } from './components/guest-form/guest-form.component';
import { MemberSearchComponent } from './components/member-search/member-search.component';
import { TransactionConfirmationComponent } from './components/transaction-confirmation/transaction-confirmation.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: 'features',
      component: TransactionComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: '',
          component: AccountFeaturesComponent,
          canActivate: [AuthGuard]
        },
        {
          path: 'deposit',
          component: DepositComponent,
          canActivate: [AuthGuard, RoleGuard],
          children: [
            { path: 'member', component: MemberSearchComponent },
            { path: 'guest', component: GuestFormComponent },
            { path: 'manual-card', component: CardEntryComponent }
          ]
        },
        {
          path: 'donate',
          component: DonateComponent,
          canActivate: [AuthGuard, RoleGuard],
          children: [
            { path: 'member', component: MemberSearchComponent },
            { path: 'guest', component: GuestFormComponent },
            { path: 'manual-card', component: CardEntryComponent }
          ]
        },
        {
          path: 'member-payment',
          component: MemberPaymentComponent,
          canActivate: [AuthGuard, RoleGuard],
          children: [
            { path: 'member', component: MemberSearchComponent },
            { path: 'manual-card', component: CardEntryComponent }
          ]
        },
        {
          path: 'manual-card',
          component: CardEntryComponent,
          canActivate: [AuthGuard]
        }
      ]
    },
    {
      path: 'confirmation',
      component: TransactionConfirmationComponent,
      canActivate: [AuthGuard]
    }
  ])],
  exports: [RouterModule]
})
export class TransactionRoutingModule { }
