import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ApplicationInsightsService } from 'src/app/shared/services/appinsight.service';
import { Subscription } from 'rxjs';
import {
  PaymentType,
  AccountFeature
} from 'src/app/shared/constants';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'account-features',
  templateUrl: './account-features.component.html',
  styleUrls: ['./account-features.component.scss']
})
export class AccountFeaturesComponent implements OnInit, OnDestroy {
  accountFeatures = [];
  changeChapterSubscription: Subscription;

  constructor(
    private storageService: StorageService,
    private emitterService: EmitterService,
    private navigationService: NavigationService,
    private applicationInsightsService: ApplicationInsightsService
  ) {}

  selectFeature(feature) {
    switch (feature.featureName) {
      case PaymentType.Deposit:
        this.applicationInsightsService.flush();
        this.navigationService.navigateToDeposit();
        break;

      case PaymentType.Donation:
        this.applicationInsightsService.flush();
        this.navigationService.navigateToDonation();
        break;

      case PaymentType.PersonPayment:
        this.applicationInsightsService.flush();
        this.navigationService.navigateToMember();
        break;
    }
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('AccountFeaturesPage'));

    this.changeChapterSubscription = this.emitterService.chapterChange.subscribe(
      isChapterChanged => {
        if (isChapterChanged) {
          this.getAccountFeatures();
        }
      }
    );
  }

  getAccountFeatures() {
    this.accountFeatures = [];
    const selectedChapId = this.storageService.getContext('SelectedChapId');

    if (selectedChapId) {
      const navAccessItems = this.storageService.getContext('NavAccess');

      navAccessItems.forEach(item => {
        switch (item.Name) {
          case AccountFeature.Deposit:
            if (item.Value) {
              this.accountFeatures.push({
                featureName: PaymentType.Deposit,
                isAllowed: item.Value
              });
            }
            break;
          case AccountFeature.Donation:
            if (item.Value) {
              this.accountFeatures.push({
                featureName: PaymentType.Donation,
                isAllowed: item.Value
              });
            }
            break;
          case AccountFeature.PersonPayment:
            if (item.Value) {
              this.accountFeatures.push({
                featureName: PaymentType.PersonPayment,
                isAllowed: item.Value
              });
            }
            break;
          default:
            break;
        }
      });
    }
  }

  ngOnDestroy() {
    this.changeChapterSubscription.unsubscribe();
    this.applicationInsightsService.flush();
  }
}
