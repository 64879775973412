import { Injectable } from "@angular/core";
import { UserProfile } from "src/app/transaction/models/UserProfile";
import { UtilService } from "./util.service";
import { EmitterService } from "./emitter.service";
import { environment } from 'src/environments/environment';

@Injectable()
export class StorageService {
  loggedIn = false;
  appTokenKey = "SecurityToken";
  applicationConfigurations = [];

  constructor(
    private utilService: UtilService,
    private emitterService: EmitterService
  ) {}

  isLoggedIn() {
    this.loggedIn = !!this.getAccessToken();
    return this.loggedIn;
  }

  getAccessToken() {
    return this.utilService.jwtDecode(sessionStorage.getItem(this.appTokenKey));
  }

  getContext(key: string) {
    if (this.emitterService.userProfile) {
      return this.emitterService.userProfile[key];
    } else {
      const userProfile = JSON.parse(
        this.utilService.jwtDecode(sessionStorage.getItem("UserProfile"))
      );
      this.emitterService.userProfile = userProfile;
      return userProfile && userProfile[key];
    }
  }

  setProfileContext(userProfile: UserProfile) {
    this.loggedIn = true;
    sessionStorage.setItem(
      this.appTokenKey,
      this.utilService.jwtEncode(userProfile.SecurityToken)
    );
    sessionStorage.setItem(
      "UserProfile",
      this.utilService.jwtEncode(JSON.stringify(userProfile))
    );

    this.emitterService.userProfile = userProfile;
  }

  setContext(key: string, value: any) {
    if (this.emitterService.userProfile) {
      this.emitterService.userProfile[key] = value;
      this.setProfileContext(this.emitterService.userProfile);
    } else {
      const userProfile = JSON.parse(
        this.utilService.jwtDecode(sessionStorage.getItem("UserProfile"))
      );
      userProfile[key] = value;
      this.setProfileContext(userProfile);
    }
  }

  public clearStorage(): void {
    this.emitterService.linkedAccounts = [];
    sessionStorage.clear();
    return sessionStorage.clear();
  }

  setValueContext(key: any, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getValueContext(key: any): any {
    return sessionStorage.getItem(key);
  }

  getConfigValue(key: string) {
    let configValue;
    if (this.applicationConfigurations.length > 0) {
      for (let i = 0; i < this.applicationConfigurations.length; i++) {
        if (this.applicationConfigurations[i].hasOwnProperty(key)) {
          configValue = this.applicationConfigurations[i][key];
          break;
        }
      }
    } else {
      configValue = environment[key];
    }
    return configValue;
  }
}
