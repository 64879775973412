import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { ForgotUsernameComponent } from './forgotusername/forgotusername.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { AuthGuard } from '../shared/services/auth.guard';

@NgModule({
  imports: [
    RouterModule.forChild([
      { path: '', component: LoginComponent },
      {
        path: 'forgotusername',
        component: ForgotUsernameComponent
      },
      {
        path: 'forgotpassword',
        component: ForgotPasswordComponent
      },
      {
        path: '**',
        redirectTo: 'transaction/features',
        pathMatch: 'full',
        canActivate: [AuthGuard]
      }
    ])
  ],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
