import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { getErrorMessage, MessageType, NonAccessibleRoles, scrollToTop, scrollToBottom, emailRegexPattern, emailMaxLength } from 'src/app/shared/constants';
import { LoginService } from '../login.service';
import { UserAccount } from 'src/app/transaction/models/UserAccount';
import { ApplicationInsightsService, SeverityLevel } from 'src/app/shared/services/appinsight.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'app-home',
  templateUrl: './forgotusername.component.html',
  styleUrls: ['./forgotusername.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ForgotUsernameComponent implements OnInit, OnDestroy {
  forgotUsernameForm: FormGroup;
  submitted = false;
  isMultipleAccounts: boolean;
  linkedAccounts: UserAccount[];
  filteredAccounts: UserAccount[] = [];
  searchValue: string;
  isClearIcon = false;
  public busy: Subscription;
  isEmailFocus = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private navigationService: NavigationService,
    private messageService: MessageService,
    private applicationInsightsService: ApplicationInsightsService,
    private errorService: ErrorHandleService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('ForgotUsernamePage'));
    this.forgotUsernameForm = this.formBuilder.group({
      emailAddress: ['',
      [
        Validators.required,
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]],
      userId: ['']
    });
  }

  get form() {
    return this.forgotUsernameForm.controls;
  }

  searchAccounts() {
    this.filteredAccounts = this.linkedAccounts.filter(account => {
      return (
        (this.searchValue ? this.searchValue.trim() : '') === '' ||
        account.DisplayName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        ) ||
        account.ChapterName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        )
      );
    });
  }

  resetFilters() {
    scrollToTop();
    this.filteredAccounts = this.linkedAccounts;
  }

  onSubmit() {
    this.submitted = true;
    if (this.forgotUsernameForm.invalid) {
      return;
    }
    if (this.isMultipleAccounts && !this.form.userId.value) {
      return;
    }

    const component = this;
    this.loginService
      .ForgetUsername(
        this.form.emailAddress.value,
        this.form.userId.value
      )
      .subscribe(
        () => {
          // show username to user
          scrollToTop();
          this.isMultipleAccounts = false;
          this.form.userId.setValue('');
          this.messageService.add(getErrorMessage(MessageType.Sent_UserName_Success, 'usernameSuccess'));
        },
        usernameError => {
          const errorData = usernameError.error;
          const errorInfo = this.utilService.prepareAppInsightError(
            usernameError,
            'ForgotUsername',
            'ForgotUsername - ForgetUsername',
            { url: usernameError.url, status: usernameError.status }
          );
          component.errorService.AppInsightLogError(errorInfo).subscribe(
            () => {},
            err => {
              component.applicationInsightsService.trackException(usernameError, SeverityLevel.Error);
            }
          );

          if (errorData && errorData.hasOwnProperty('Messages')) {
            if (errorData.Messages[0].Code === '400.20107') {
              this.loginService.GetAllUserIds(this.form.emailAddress.value).subscribe(
                accounts => {
                  this.linkedAccounts = accounts.filter(account => {
                    return !NonAccessibleRoles.includes(account.UserRole.toLowerCase())
                  })
                  this.filteredAccounts = this.linkedAccounts;
                  if (this.linkedAccounts.length !== 0) {
                    this.isMultipleAccounts = (this.linkedAccounts.length !== 1);
                    if (!this.isMultipleAccounts) {
                      this.form.userId.setValue(this.linkedAccounts[0].UserId);
                      this.onSubmit();
                    }
                    scrollToBottom();
                    this.submitted = false;
                  } else {
                    this.messageService.add(getErrorMessage(MessageType.UserRole_Not_Allowed, 'sentWithError'));
                  }
                },
                userIdError => {
                  this.errorService.handleErrorResponse(userIdError);
                }
              );
            } else {
              this.errorService.handleErrorResponse(usernameError);
            }
          } else {
            component.applicationInsightsService.trackException(usernameError, SeverityLevel.Error);
            this.messageService.add(getErrorMessage(MessageType.Search_Username_Error, 'sentWithError'));
          }
        }
      );
  }

  navigateToLogin() {
    this.applicationInsightsService.flush();
    this.navigationService.navigateToLogin();
  }

  ngOnDestroy() {
    this.applicationInsightsService.flush();
  }
}
