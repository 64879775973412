import { DonorInfo } from './donorInfo';

export class DepositPayment {
  EncryptedPaymentData: string;
  IntegrationPartnerId: number;
  ChapAcctId: number;
  ChapBillCatId: number;
  TokenId: string;
  DonorInfo: DonorInfo;
}
