export const environment = {
  production: false,
  API: "/Chapters/",
  AnywhereAPI: "/Anywhere/api/",
  encode: {
    algorithm: "HS256",
    key: "B!lLHIghvv@Y"
  },
  googleRecaptchaKey: "6LcvKOIUAAAAAEwT0ZajKzNa-oCp6dnw76VsOEaG",
  appInsightsConfig: {
    instrumentationKey: "4e2f47f7-d59e-4b65-b68a-38e55c12d08e",
    traces: true,
    events: true,
    pageviews: true,
    exceptions: true,
    dependencies: true
  },
  gtagConfigKey: "UA-156255989-1",
  paymentChargeApi:
    "https://devapi.billhighway.com/integrations/api/standard/charge/token",
  chargePaymentAuthValue: "PublishableKey C2AB9AF1-52A1-4827-827B-6BB63CF8169F",
  isEMVTransactionEnabled: false,
  supportCardType: "01",
  cardReaderSwipeConfigData: {
    timeLimit: "28",
    customDisplayMessage: "Please Swipe Card"
  },
  cardReaderEMVConfigData: {
    option: "00",
    timeLimit: "28",
    currencyCode: "0840",
    transactionType: "00",
    quickChipMode: "true",
    endSession: "true",
    cashBack: "0.00"
  }
};
