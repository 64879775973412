import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginService } from './login.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StorageService } from '../shared/services/storage.service';
import { ForgotUsernameComponent } from './forgotusername/forgotusername.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MessageService } from 'primeng/api';
import { ApplicationInsightsService } from '../shared/services/appinsight.service';

@NgModule({
  imports: [
    LoginRoutingModule, RouterModule, ReactiveFormsModule,
    CommonModule, RadioButtonModule, FormsModule,
    InputTextModule, ToastModule, DialogModule, TableModule, DropdownModule],
  declarations: [LoginComponent, ForgotUsernameComponent, ForgotPasswordComponent],
  providers: [LoginService, StorageService, MessageService, ApplicationInsightsService]
})
export class LoginModule { }
