import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApplicationInsightsService } from 'src/app/shared/services/appinsight.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { PageViewTelemetry } from 'src/app/shared/models/application-insights/pageview.telemetry.model';
import { Dropdown } from 'primeng/dropdown';
import { emailRegexPattern, emailMaxLength } from 'src/app/shared/constants';

@Component({
  selector: 'guest-form',
  templateUrl: './guest-form.component.html',
  styleUrls: ['./guest-form.component.scss']
})
export class GuestFormComponent implements OnInit {
  @Input() guestType: string;
  @Output() guestSelect = new EventEmitter();
  @ViewChild(Dropdown, {static : false}) dropdown: Dropdown;

  type = 'guest';
  guestEntryForm: FormGroup;
  submitted = false;
  isNameFocus = false;
  isEmailFocus = false;
  states = [];
  filteredStates: string[] = [];
  isAddress1Focus = false;
  isAddress2Focus = false;
  isCityFocus = false;
  isStateFocus = false;
  isPostalCodeFocus = false;

  constructor(private applicationInsightsService: ApplicationInsightsService, private utils: UtilService) {
    this.states = utils.getStates();

    this.guestEntryForm = new FormGroup({
      Name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      Email: new FormControl('', [
        Validators.required,
        Validators.pattern(emailRegexPattern),
        Validators.maxLength(emailMaxLength)
      ]),
      AddressLine1: new FormControl('', [Validators.maxLength(100)]),
      AddressLine2: new FormControl('', [Validators.maxLength(100)]),
      City: new FormControl('', [Validators.maxLength(100)]),
      State: new FormControl('', [Validators.maxLength(100)]),
      PostalCode: new FormControl('', [Validators.maxLength(10)])
    });
  }

  get form() {
    return this.guestEntryForm.controls;
  }

  submitGuestDetails() {
    if (this.guestEntryForm.valid) {
      const state = this.form.State.value;
      if (state) {
        this.form.State.setValue(state.value);
      }
      this.guestSelect.emit(this.guestEntryForm.value);
    }
    this.submitted = true;
  }


  filterStates({query}) {
    const states = [];
    this.states.forEach((state) => {
      if ((state.label.toLowerCase()).includes(query.toLowerCase())) {
        states.push(state);
      }
    });
    this.filteredStates = states;
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('GuestFormPage'));
    this.guestEntryForm.reset();
  }
}
