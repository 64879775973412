import { SeverityLevel } from '@microsoft/applicationinsights-web';

export class ExceptionTelemetry {
  id?: string;
  exception?: Error;
  severityLevel?: SeverityLevel | number;

  constructor(exception?: Error, severityLevel?: SeverityLevel) {
    this.exception = exception;
    this.severityLevel = severityLevel;
  }
}
