import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { APIRequest } from './../shared/models/api.request.model';
import { APIMethod, ApiService } from './../shared/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoginService {
  constructor(
    private apiService: ApiService
  ) {}

  public Login(username: string, password: string, userId: number) {
    const body =
      userId
        ? {
            UserName: username,
            Password: password,
            UserId: userId
          }
        : {
            UserName: username,
            Password: password
          };
    const apiRequest: APIRequest = new APIRequest(
      'Login',
      APIMethod.POST,
      body
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public verifyCaptcha(recaptchaResponse: string) {
    const apiRequest: APIRequest = new APIRequest(
      'Captcha',
      APIMethod.POST,
      '"' + recaptchaResponse + '"', environment.AnywhereAPI
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public GetAllUserIds(emailAddress: string) {
    const apiRequest: APIRequest = new APIRequest(
      `LoginAccounts/${emailAddress}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public getUserLinkedAccounts(userName: string) {
    const apiRequest: APIRequest = new APIRequest(
      `LoginLinkedAccounts/${userName}`,
      APIMethod.GET
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public ForgetUsername(emailAddress: string, userId: number) {
    const body =
      userId
        ? {
          Email: emailAddress,
          UserId: userId
        }
        : {
          Email: emailAddress
        }
    const apiRequest: APIRequest = new APIRequest(
      'Loginforgotusername',
      APIMethod.POST,
      body
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public ForgetPasswordRequestCode(email: string, userId: any) {
    const body =
      !userId.hasOwnProperty('UserId')
        ? {
          Email: email
        }
        : {
          Email: email,
          UserId: userId.UserId
        }
    const apiRequest: APIRequest = new APIRequest(
      'LoginForgotPassword',
      APIMethod.POST,
      body
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public ForgetPasswordValidateCode(
    emailAddress: string,
    activationCode: string
  ) {
    const apiRequest: APIRequest = new APIRequest(
      'LoginVerifyActivationCode',
      APIMethod.POST,
      {
        Email: emailAddress,
        ActivationCode: activationCode
      }
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  public ResetPassword(
    emailAddress: string,
    activationCode: string,
    userId: number,
    newPassword: string
  ) {
    const apiRequest: APIRequest = new APIRequest(
      'LoginResetPassword',
      APIMethod.POST,
      {
        Email: emailAddress,
        ActivationCode: activationCode,
        UserID: userId,
        Password: newPassword
      }
    );

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }
}
