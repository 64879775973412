import { APIMethod } from '../services/api.service';
import { HttpHeaders } from '@angular/common/http';

export class APIRequest {
  public endpoint: string;
  public method: APIMethod;
  public headers: HttpHeaders;
  private rawBody: any;
  public baseUrl: string;
  public paymentType: string;

  constructor(
    endpoint: string,
    method: APIMethod,
    payload?: any,
    baseUrl?: string,
    paymentType?: string
  ) {
    this.endpoint = endpoint;
    this.method = method;
    this.rawBody = payload;
    this.baseUrl = baseUrl;
    this.paymentType = paymentType;
  }

  getPayload() {
    return this.rawBody;
  }
}
