import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  navigateToLogin() {
    this.router.navigate(['']);
  }

  navigateToDashboard() {
    this.router.navigate(['transaction', 'features']);
  }

  navigateToDeposit() {
    this.router.navigate(['transaction', 'features', 'deposit']);
  }

  navigateToDonation() {
    this.router.navigate(['transaction', 'features', 'donate']);
  }

  navigateToMember() {
    this.router.navigate(['transaction', 'features', 'member-payment']);
  }

  navigateToConfirmation() {
    this.router.navigate(['transaction', 'confirmation']);
  }

  navigateToCardEntry(component) {
    this.router.navigate(['transaction', 'features', component , 'manual-card']);
  }

  internalServerError() {
    this.router.navigate(['/internal-server-error']);
  }

  notFoundError() {
    this.router.navigate(['/page-not-found']);
  }

  navigateTo(page: string) {
    this.router.navigate([page]);
  }
}
