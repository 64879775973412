import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { MessageService } from 'primeng/api';
import { getErrorMessage, MessageType, PaymentType } from '../constants';
import { APIRequest } from '../models/api.request.model';
import { APIMethod, ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { NavigationService } from './navigation.service';

@Injectable()
export class AuthService {
  constructor(
    private storageService: StorageService,
    private messageService: MessageService,
    private apiService: ApiService,
    private navigationService: NavigationService
  ) {}

  logoutUser() {
    this.logout().subscribe(() => {}, error => {
        this.messageService.add(getErrorMessage(MessageType.API_Request_Failed, 'loginError'));
      }
    );
  }

  unauthorizedMessage() {
    this.messageService.clear('RequestFailed');
    if (this.storageService.isLoggedIn()) {
      this.logout().subscribe(() => {
        this.messageService.add(getErrorMessage(MessageType.Unauthorized_User, 'AppToast'));
      }, error => {
        this.messageService.add(getErrorMessage(MessageType.API_Request_Failed, 'loginError'));
      });
    }
  }

  receivedUnauthorizedErrorMessage(errorMessage) {
    this.messageService.clear('RequestFailed');
    if (this.storageService.isLoggedIn()) {
      this.logout().subscribe(() => {
        this.messageService.add(getErrorMessage(MessageType.Received_Error_Message, 'AppToast', errorMessage));
      }, error => {
        this.messageService.add(getErrorMessage(MessageType.API_Request_Failed, 'loginError'));
      });
    }
  }

  redirectToDashboard() {
    this.navigationService.navigateToDashboard();
  }

  redirectToServerError() {
    this.messageService.clear('RequestFailed');
    this.navigationService.internalServerError();
  }

  redirectToPageNotFoundError() {
    this.messageService.clear('RequestFailed');
    this.navigationService.notFoundError();
  }

  public logout() {
    this.storageService.clearStorage();
    this.navigationService.navigateToLogin();

    const apiRequest: APIRequest = new APIRequest('Logout', APIMethod.POST);

    return this.apiService.executeAPI(apiRequest).pipe(
      map(response => {
        return response;
      })
    );
  }

  checkUserAccessPermission(featureName: string) {
    let isPermissionAvailable = false;

    switch (featureName) {
      case PaymentType.Deposit:
        isPermissionAvailable = this.checkUserPermission('CanAcceptAnywhereDeposits-Card');
        break;
      case PaymentType.Donation:
        isPermissionAvailable = this.checkUserPermission('CanAcceptAnywhereDonations-Card');
        break;
      case PaymentType.PersonPayment:
        isPermissionAvailable = this.checkUserPermission('CanAcceptAnywherePayments-Card');
        break;
      default:
        break;
    }

    if (isPermissionAvailable) {
      return true;
    } else {
      this.redirectToDashboard();
      return false;
    }
  }

  checkUserPermission(accountFeature: string) {
    const navAccessItems = this.storageService.getContext('NavAccess');

    const accessItems = navAccessItems.filter(item => {
      if (accountFeature === item.Name) {
        return item.Value;
      }
    });

    return (accessItems.length !== 0);
  }
}
