export class MemberInvoice {

  constructor(InvNo: string, Amount: number) {
    this.InvNo = InvNo;
    this.Amount = Amount;
  }

  InvNo: string;
  Amount: number;
}
