import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { StorageService } from '../../services/storage.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentPage: string;
  showSideBar: boolean;
  sidebarMenus: any;
  isLoggedIn: boolean;
  displayRedirectModal = false;
  redirectLink = '';
  errorPage: boolean;

  changerChangeSubscription: Subscription;
  errorPageSubscription: Subscription;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private emitterService: EmitterService,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.currentPage = event.url
          .split('/')
          .splice(1, 2)
          .toLocaleString();

        this.currentPage =
          this.currentPage === '' || this.currentPage === 'login'
            ? 'header-container-login'
            : 'header-container-other';

        if (
          event.url
            .split('/')
            .splice(1, 2)
            .toLocaleString() === 'transaction'
        ) {
          this.navigationService.navigateToLogin();
        }

        this.initSidebarMenu();
      }
    });
  }

  initSidebarMenu() {
    this.sidebarMenus = [];

    this.isLoggedIn = this.storageService.isLoggedIn();
    if (this.isLoggedIn) {
      this.sidebarMenus.push({
        label: 'Home',
        route: 'transaction/features'
      });

      const navAccessItems = this.storageService.getContext('NavAccess');
      navAccessItems.forEach(item => {
        switch (item.Name) {
          case 'CanAcceptAnywhereDeposits-Card':
            if (item.Value) {
              this.sidebarMenus.push({
                label: 'Deposit',
                route: 'transaction/features/deposit'
              });
            }
            break;
          case 'CanAcceptAnywhereDonations-Card':
            if (item.Value) {
              this.sidebarMenus.push({
                label: 'Donation',
                route: 'transaction/features/donate'
              });
            }
            break;
          case 'CanAcceptAnywherePayments-Card':
            if (item.Value) {
              this.sidebarMenus.push({
                label: 'Member Payment',
                route: 'transaction/features/member-payment'
              });
            }
            break;
          default:
            break;
        }
      });

      this.sidebarMenus.push({
        label: 'Contact Us',
        href: 'https://www.billhighway.co/contact-us/'
      });
      this.sidebarMenus.push({ logout: true });
    } else {
      this.sidebarMenus = [
        { label: 'Login', route: '' },
        { label: 'Contact Us', href: 'https://www.billhighway.co/contact-us/' }
      ];
    }
  }

  openSidebar() {
    this.showSideBar = !this.showSideBar;
    this.initSidebarMenu();
  }

  navigateToDashboard() {
    if (this.storageService.isLoggedIn()) {
      this.navigationService.navigateToDashboard();
    }
  }

  externalRedirect(event, link) {
    if (link) {
      event.preventDefault();
      this.displayRedirectModal = true;
      this.redirectLink = link;
    }
  }

  logoutUser() {
    this.authService.logoutUser();
  }

  redirectAction(shouldProceed: boolean) {
    if (shouldProceed) {
      this.authService.logout().subscribe(() =>{
        this.storageService.clearStorage();
        window.location.href = this.redirectLink;
      });
    } else {
      this.displayRedirectModal = false;
    }
  }

  ngOnInit() {
    this.errorPageSubscription = this.emitterService.errorPage.subscribe(response => {
      this.errorPage = response;
    });

    this.changerChangeSubscription = this.emitterService.chapterChange.subscribe(isChapterChanged => {
      if (isChapterChanged) {
        this.initSidebarMenu();
      }
    });
  }

  ngOnDestroy(): void {
    this.changerChangeSubscription.unsubscribe();
    this.errorPageSubscription.unsubscribe();
  }
}
