export class AchInfoData {
  AccountHolderName: string;
  AccountNumber: string;
  AccountType: string;
  RoutingNumber: string;
}

export class CardInfoData {
  Attributes: AttributesData[];
  CardNumber: string;
  Cvv: string;
  ExpMonth: number;
  ExpYear: number;
  NameOnCard: string;
}

export class AttributesData {
  Name: string;
  Value: string;
}

export class ReferenceIdData {
  Type: string;
  Value: string;
}

export class OptionsData {
  Duration: number;
  Usages: number;
}

export class PaymentData {
  AchInfo: AchInfoData[];
  Amount: number;
  CardInfo: CardInfoData;
  Currency: string;
  Description: string;
  Options: OptionsData;
  ReferenceIds: ReferenceIdData[];
  tokenType: string;
}

export class UserPaymentData {
  UserId: number;
  Amount: number;
  Memo: string;
  ReceiptEmail: string;
}

export class UserProfileData {
  ChapterId: number;
  NationalId: number;
  RequestingUserId: number;
}
