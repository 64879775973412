import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { InternalServerErrorComponent } from './shared/component/internal-server-error/internal-server-error.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'login'
        },
        {
          path: 'login',
          loadChildren: './login/login.module#LoginModule'
        },
        {
          path: 'transaction',
          loadChildren: './transaction/transaction.module#TransactionModule'
        },
        {
          path: 'internal-server-error',
          component: InternalServerErrorComponent,
        },
        {
          path: 'page-not-found',
          component: PageNotFoundComponent,
        },
        { path: '**', pathMatch: 'full', component: PageNotFoundComponent}
      ],
      { useHash: true }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
