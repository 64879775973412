import { SeverityLevel } from '@microsoft/applicationinsights-web';

export class TraceTelemetry {
  message: string;
  severityLevel?: SeverityLevel;

  constructor(message: string, severityLevel?: SeverityLevel) {
    this.message = message;
    this.severityLevel = severityLevel;
  }
}
