import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer.component';
import { FooterRoutingModule } from './footer-routing.module';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';

@NgModule({
  imports: [RouterModule, FooterRoutingModule, CommonModule, MenuModule, DialogModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
  providers: [MessageService]
})
export class FooterModule { }
