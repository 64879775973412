import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmitterService } from 'src/app/shared/services/emitter.service';
import { NavigationService } from '../../services/navigation.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean;

  constructor(
    private emitterService: EmitterService,
    private navigationService: NavigationService,
    private storageService: StorageService
  ) {
    this.isLoggedIn = this.storageService.isLoggedIn();
  }

  ngOnInit() {
    setTimeout(() => {
      this.emitterService.errorPageChanged(true);
    });
  }

  ngOnDestroy() {
    this.emitterService.errorPageChanged(false);
  }

  goToHome() {
    setTimeout(() => {
      this.emitterService.errorPageChanged(false);
    });
    if (this.storageService.isLoggedIn()) {
      this.navigationService.navigateToDashboard();
    } else {
      this.navigationService.navigateToLogin();
    }
  }
}
