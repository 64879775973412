import { Component, OnInit, OnDestroy } from '@angular/core';
import { EmitterService } from '../shared/services/emitter.service';
import { StorageService } from '../shared/services/storage.service';
import { MessageService } from 'primeng/api';
import {
  getErrorMessage,
  MessageType,
  scrollToTop,
  scrollToBottom,
  getRouteName,
  NonAccessibleRoles
} from '../shared/constants';
import { TransactionService } from './services/transaction.service';
import { Chapter } from './models/Chapter';
import { UserAccount } from './models/UserAccount';
import { Subscription } from 'rxjs';
import { ApplicationInsightsService } from '../shared/services/appinsight.service';
import { AuthService } from '../shared/services/auth.service';
import { NavigationService } from '../shared/services/navigation.service';
import { ErrorHandleService } from '../shared/services/error-handle.service';
import { NavAccessItem } from './models/NavAccessItem';
import { AvailableChapter } from './models/AvailableChapter';
import { UserProfile } from './models/UserProfile';
import { PageViewTelemetry } from '../shared/models/application-insights/pageview.telemetry.model';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit, OnDestroy  {
  chapters: Chapter[];
  linkedAccounts: UserAccount[] = [];

  searchValue: string;
  selectedChapter: string;
  currentFeature: string;
  selectedAccount: UserAccount = new UserAccount() ;
  isClearIcon = false;
  isDefaultNotSet = true;
  isCardEntryPage: boolean;
  showChapterChangeDialog: boolean;
  showAccountChangeDialog: boolean;
  showChangeAccountLink: boolean;
  isSingleChapter: boolean;
  loginProfile: UserProfile;
  filteredChapters: Chapter[] = [];
  filteredAccounts: UserAccount[] = [];

  displayRedirectModal = false;
  redirectLink = '';

  accountFeatureSubscription: Subscription;
  getAccountSubscription: Subscription;
  accountChangeSubscription: Subscription;
   constructor(
    private emitterService: EmitterService,
    private transactionService: TransactionService,
    private storageService: StorageService,
    private messageService: MessageService,
    private authService: AuthService,
    private navigationService: NavigationService,
    private applicationInsightsService: ApplicationInsightsService,
    private errorService: ErrorHandleService
  ) {}

  getChapters() {
    this.isSingleChapter = false;
    const availableChapters = this.storageService.getContext('AvailableChapters');
    this.chapters = availableChapters;
    this.filteredChapters = this.chapters;

    if (availableChapters.length === 0) {
      this.messageService.add(getErrorMessage(MessageType.No_Chapter_Found, 'Transaction'));
    } else if (availableChapters.length === 1) {
      this.isSingleChapter = true;
      this.setDefaultChapter(this.storageService.getContext('ChapId'));
      this.changeChapter(availableChapters[0]);
    } else {
      const selectedChapId = this.storageService.getContext('SelectedChapId');
      if (selectedChapId !== undefined) {
        this.setDefaultChapter(selectedChapId);
      } else {
        this.showChapterChangeDialog = true;
      }
    }
  }

  getUserLinkedAccounts() {
    if (this.emitterService.linkedAccounts.length === 0) {
     this.getAccountSubscription = this.transactionService
      .getUserLinkedAccounts(this.storageService.getContext('Username'))
      .subscribe(
        linkedAccounts => {
          if (linkedAccounts.length > 0) {
            this.linkedAccounts = linkedAccounts.filter((linkedAccount) => {
              return (!NonAccessibleRoles.includes(linkedAccount.UserRole.toLowerCase()));
            });

            this.emitterService.linkedAccounts = this.linkedAccounts;
            this.setSelectedAccount(this.emitterService.linkedAccounts);
          } else {
            this.messageService.add(getErrorMessage(MessageType.No_Account_Found, 'NotAvailable'));
          }
        },
        error => {
          this.errorService.handleErrorResponse(error, 'Transaction', 'getUserLinkedAccounts');
        }
      );
    } else {
      this.linkedAccounts = this.emitterService.linkedAccounts;
      this.setSelectedAccount(this.emitterService.linkedAccounts);
    }
  }

  setSelectedAccount(filteredLinkedAccounts: UserAccount[]) {
    this.filteredAccounts = filteredLinkedAccounts;
    this.filteredAccounts.forEach(currentAccount => {
      if (currentAccount.UserId === this.storageService.getContext('UserId')) {
        this.selectedAccount = currentAccount;
      }
    });
    this.getChapters();
  }

  setDefaultChapter(chapterId: number) {
    this.chapters.forEach(ch => {
      if (chapterId === ch.ChapterId) {
        ch.isDefault = true;
        this.selectedChapter = ch.ChapterDescription;
        this.emitterService.chapterChanged(true);
      } else {
        ch.isDefault = false;
      }
    });
  }

  changeChapter(newChapter: Chapter) {
    const chapters: Chapter[] =  this.storageService.getContext('AvailableChapters');

    this.selectedChapter = newChapter.ChapterDescription;
    chapters.forEach(chapter => {
      if (newChapter.ChapterId === chapter.ChapterId) {
        chapter.isDefault = true;
        this.changeAccountFeaturePermission(chapter.ChapterId);
      } else {
        chapter.isDefault = false;
      }
    });
  }

  changeAccountFeaturePermission(chapterId: number) {
    const availableChapters = this.storageService.getContext('AvailableChapters');

    availableChapters.forEach(accountFeature => {
      if (accountFeature.ChapterId === chapterId) {
        const availableFeatures = accountFeature.NavAccess.filter((navItem: NavAccessItem) => {
          return navItem.Value && navItem.Name !== 'HasAnywhereAccess';
        });

        this.storageService.setContext('ChapId', chapterId);
        this.storageService.setContext('SelectedChapId', chapterId);
        this.storageService.setContext('NavAccess', accountFeature.NavAccess);
        this.authService.checkUserAccessPermission(this.currentFeature);

        if (availableFeatures.length === 0) {
          this.messageService.add(getErrorMessage(MessageType.No_Account_Feature_Available, 'Transaction'));
        }

        this.emitterService.chapterChanged(true);
        this.showChapterChangeDialog = false;
      }
    });
  }

  changeAccountType(account) {
    if (account.UserId !== this.storageService.getContext('UserId')) {
      this.transactionService.changeLinkedAccount(account.UserId).subscribe(
        ({ UserProfile, SecurityToken, AvailableChapters }) => {
          this.loginProfile = UserProfile;
          this.loginProfile.SecurityToken = SecurityToken;

          if (this.checkNavAccessPermissions(this.loginProfile.NavAccess)) {
            if (NonAccessibleRoles.includes((this.loginProfile.UserRole).toLowerCase())) {
              this.messageService.add(getErrorMessage(MessageType.UserRole_Not_Allowed, 'Transaction'));
            } else {
              const availableChapters = this.filterAvailableChapters(AvailableChapters);
              this.loginProfile.AvailableChapters = availableChapters;

              if (availableChapters.length !== 0) {
                this.loginProfile.showChangeAccountLink = true;
                this.storageService.setProfileContext(this.loginProfile);
                this.emitterService.accountChanged(true);
                this.navigationService.navigateToDashboard();
              } else {
                this.messageService.add(getErrorMessage(MessageType.No_Chapter_Found, 'Transaction'));
              }
            }
          } else {
            this.messageService.add(getErrorMessage(MessageType.Anywhere_Payment_Not_Enabled, 'Transaction'));
          }
          this.showAccountChangeDialog = false;
        },
        error => {
          this.showAccountChangeDialog = false;
          this.errorService.handleErrorResponse(error, 'Transaction', 'changeAccountType');
        }
      );
    } else {
      this.showAccountChangeDialog = false;
    }
  }

  checkNavAccessPermissions(navAccess: NavAccessItem[]) {
    let hasAnywhereAccess = false;
    this.loginProfile.NavAccess.forEach((navItem: NavAccessItem) => {
      if (navItem.Name === 'HasAnywhereAccess' && navItem.Value) {
        hasAnywhereAccess = true;
      }
    });
    return hasAnywhereAccess;
  }

  resetFilters() {
    scrollToTop();
    this.filteredChapters = this.chapters;
    this.filteredAccounts = this.linkedAccounts;
    const selectedChapId = this.storageService.getContext('SelectedChapId');
    if (selectedChapId === undefined) {
      this.showChapterChangeDialog = true;
    }
  }

  searchChapters() {
    this.filteredChapters = this.chapters.filter(chapter => {
      return (
        (this.searchValue ? this.searchValue.trim() : '') === '' ||
        chapter.ChapterDescription.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        ) ||
        chapter.ChapterAlternateDescription.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        )
      );
    });
  }

  searchAccounts() {
    this.filteredAccounts = this.linkedAccounts.filter(account => {
      return (
        (this.searchValue ? this.searchValue.trim() : '') === '' ||
        account.DisplayName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        ) ||
        account.ChapterName.toLocaleLowerCase().includes(
          this.searchValue.toLocaleLowerCase()
        )
      );
    });
  }

  onActivate(componentReference) {
    scrollToTop();
    this.applicationInsightsService.startTrackPage(componentReference.url);
  }

  onDeactivate(componentReference) {
    this.applicationInsightsService.stopTrackPage(componentReference.url);
    this.applicationInsightsService.stopTrackEvent(componentReference.url, { type: 'PAGE LOAD TIME' });
    this.applicationInsightsService.flush();
  }

  filterAvailableChapters(availableChapters: AvailableChapter[]) {
    return availableChapters.filter(chapter => {
      return (chapter.NavAccess.filter(navAccess => {
        return (navAccess.Name === 'HasAnywhereAccess' && navAccess.Value);
      }).length > 0
      );
    });
  }

  logoutUser() {
    if (!this.showChangeAccountLink) {
      this.authService.logoutUser();
    }
  }

  scrollToBottom() {
    scrollToBottom();
  }

  onConfirm() {
    this.messageService.clear('PaymentConfirmMessage');
    this.navigationService.navigateToCardEntry(getRouteName(this.currentFeature));
  }

  onReject() {
    this.messageService.clear('PaymentConfirmMessage');
  }

  onConfirmNonMagnaflex() {
    this.messageService.clear('BrowserNotSupported');
    this.navigationService.navigateToCardEntry(getRouteName(this.currentFeature));
  }

  onRejectNonMagnaflex() {
    this.messageService.clear('BrowserNotSupported');
  }

  externalRedirect(event, link) {
    event.preventDefault();
    this.displayRedirectModal = true;
    this.redirectLink = link;
  }

  redirectAction(shouldProceed: boolean) {
    if (shouldProceed) {
      this.authService.logout().subscribe(() => {
        this.storageService.clearStorage();
        window.location.href = this.redirectLink;
      });
    } else {
      this.displayRedirectModal = false;
    }
  }

  ngOnInit() {
    this.applicationInsightsService.trackPageView(new PageViewTelemetry('TransactionPage'));
    this.showChangeAccountLink = this.storageService.getContext('showChangeAccountLink');
    this.accountFeatureSubscription = this.emitterService.accountFeaturesChange.subscribe(
      accountFeature => {
        this.currentFeature = accountFeature;
      }
    );

    this.accountChangeSubscription = this.emitterService.accountChange.subscribe(
      isAccountChanged => {
        if (isAccountChanged) {
          // this.emitterService.linkedAccounts = [];
          this.getUserLinkedAccounts();
        }
      }
    );

    this.emitterService.cardEntryPage.subscribe((isCardEntryPage) => {
      this.isCardEntryPage = isCardEntryPage;
    });

    if (this.storageService.isLoggedIn()) {
      this.getUserLinkedAccounts();
    }
  }

  ngOnDestroy(): void {
    this.applicationInsightsService.flush();
    this.accountChangeSubscription.unsubscribe();
    this.accountChangeSubscription.unsubscribe();
  }
}
